import { Account, User } from '@brenger/api-client';
import { Config } from '../../config';
import { getCustomerBaseUrl, mainBaseUrl } from '../global';
import { _get } from '../helpers/getLodash';
import { getActiveLanguage, translate } from '../helpers/translations';
import { AccountType, NavItem, Paths } from '../interface';
import {
  getAccountType,
  getCustomerDashboardLink,
  getNewTransportRequestLink,
  getProfileImage,
  hasDedicatedFlow,
} from '../user/getUserDetails';

const getNavItems = (user: User): NavItem[] => {
  const userRoles = _get(user, 'roles', []);
  return userRoles.includes('ROLE_DRIVER') ? getDriverMenuItems(user) : getCustomerMenuItems(user);
};
export default getNavItems;

const getCustomerMenuItems = (user: User): NavItem[] => {
  switch (getAccountType(user)) {
    case AccountType.INDIVIDUAL:
      return [
        ...getBasicMenuItems(),
        {
          title: hasDedicatedFlow(user)
            ? translate('main_menu.customer.new_transport')
            : translate('main_menu.customer.new_transport_business'),
          url: getNewTransportRequestLink(user, getCustomerBaseUrl()),
          extra_classes: 'btn--container menu-index-4',
          extra_link_classes: 'btn btn--secondary',
        },
        {
          title: '',
          url: getCustomerDashboardLink(getCustomerBaseUrl()),
          image: getProfileImage(user),
          extra_classes: 'menu-index-6',
          children: getProfileSubMenu(user),
        },
      ];
    case AccountType.BUSINESS:
      return [
        {
          title: hasDedicatedFlow(user)
            ? translate('main_menu.customer.new_transport')
            : translate('main_menu.customer.new_transport_business'),
          url: getNewTransportRequestLink(user, getCustomerBaseUrl()),
          extra_classes: 'btn--container menu-index-4',
          extra_link_classes: 'btn btn--secondary',
        },
        {
          title: '',
          url: getCustomerDashboardLink(getCustomerBaseUrl()),
          image: getProfileImage(user),
          extra_classes: 'menu-index-6',
          children: getProfileSubMenu(user),
        },
      ];
    default:
      return getBasicMenuItems();
  }
};

const getBasicMenuItems = (): NavItem[] => {
  if (getActiveLanguage() === 'en-nl') {
    return [
      {
        title: translate('main_menu.customer.prices'),
        url: `${mainBaseUrl}/prices`,
        extra_classes: 'menu-index-1',
      },
      {
        title: translate('main_menu.customer.how_it_works'),
        url: `${mainBaseUrl}/how-it-works`,
        extra_classes: 'menu-index-2',
      },
      {
        title: translate('main_menu.customer.for_business'),
        url: `${mainBaseUrl}/companies`,
        extra_classes: 'menu-index-3',
      },
    ];
  }
  return [
    {
      title: translate('main_menu.customer.prices'),
      url: `${mainBaseUrl}/prijzen`,
      extra_classes: 'menu-index-1',
    },
    {
      title: translate('main_menu.customer.how_it_works'),
      url: `${mainBaseUrl}/hoe-het-werkt`,
      extra_classes: 'menu-index-2',
    },
    {
      title: translate('main_menu.customer.for_business'),
      url: `${mainBaseUrl}/bedrijven`,
      extra_classes: 'menu-index-3',
    },
  ];
};

const getDriverMenuItems = (user: User): NavItem[] => {
  return [
    {
      title: translate('main_menu.driver.dashboard'),
      url: `${Config.BRENGER_DRIVER_BASE_URL}/profile`,
      extra_classes: 'menu-index-1',
    },
    {
      title: translate('main_menu.driver.jobs'),
      url: `${Config.BRENGER_DRIVER_BASE_URL}/planning`,
      extra_classes: 'menu-index-2',
    },
    {
      title: translate('main_menu.driver.how_it_works'),
      url: `${mainBaseUrl}/brenger-voor-koeriers`,
      extra_classes: 'menu-index-3',
    },
    {
      title: translate('main_menu.driver.messages'),
      url: `${Config.BRENGER_DRIVER_BASE_URL}/inbox`,
      extra_classes: 'menu-index-4',
    },
    {
      title: '',
      url: `${Config.BRENGER_DRIVER_BASE_URL}/profile/details`,
      image: getProfileImage(user),
      extra_classes: 'menu-index-5',
      children: [
        {
          title: translate('main_menu.profile'),
          url: `${Config.BRENGER_DRIVER_BASE_URL}/profile/details`,
          extra_classes: 'menu-index-6',
        },
        {
          title: translate('main_menu.logout'),
          url: `${Config.API_URL}${Paths.LOGOUT}`,
          extra_classes: 'menu-index-7 dd-nav-item--offset color-grey',
        },
      ],
    },
  ];
};

const getProfileSubMenu = (user: User): NavItem[] => {
  const subItems = [
    {
      title: translate('main_menu.customer.dashboard'),
      url: getCustomerDashboardLink(getCustomerBaseUrl()),
      extra_classes: 'menu-index-7',
    },
    {
      title: translate('main_menu.customer.transport_overview'),
      url: Config.TRACKING_BASE_URL,
      extra_classes: 'menu-index-8',
    },
  ];

  const hasApiSettings = (user.account as Account)?.account_type === 'business';
  if (hasApiSettings) {
    subItems.push({
      title: translate('main_menu.api_settings'),
      url: `${getCustomerBaseUrl()}/api_settings`,
      extra_classes: 'menu-index-9',
    });
  }
  subItems.push({
    title: translate('main_menu.logout'),
    url: `${Config.API_URL}${Paths.LOGOUT}`,
    extra_classes: `menu-index-${hasApiSettings ? '10' : '9'} color-grey`,
  });

  return subItems;
};
