"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.IconBrengerSmile = void 0;
var React = __importStar(require("react"));
var IconBrengerSmile = function (props) { return (React.createElement("svg", __assign({ width: "12", height: "6", viewBox: "0 0 12 6", fill: "currentColor", xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M11.6993 0H9.34111C9.20191 0 9.0747 0.0996 9.0483 0.246C8.76868 1.7556 7.50981 2.9004 6.00012 2.9004C4.49163 2.9004 3.23156 1.7556 2.95194 0.246C2.92434 0.0996 2.79953 0 2.66032 0H0.300984C0.123374 0 -0.0194344 0.1608 0.00216684 0.3468C0.347787 3.5244 2.90514 6 6.00012 6C9.0951 6 11.6524 3.5244 11.9981 0.3468C12.0185 0.1608 11.8745 0 11.6993 0Z", fill: "currentColor" }))); };
exports.IconBrengerSmile = IconBrengerSmile;
