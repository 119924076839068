"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.IconBrengerLogo = void 0;
var React = __importStar(require("react"));
var IconBrengerLogo = function (props) {
    return (React.createElement("svg", __assign({ width: "3.625em", height: "1em", viewBox: "0 0 58 16", fill: "currentColor" }, props),
        React.createElement("path", { d: "M57.8537 3.99595C57.9086 3.89217 57.8575 3.76395 57.7464 3.72639L57.2782 3.56839L57.2768 3.57017C56.8373 3.43883 56.3935 3.38306 55.9922 3.38306C54.4202 3.38306 52.1729 4.16017 52.1729 7.33261V12.1531C52.1729 12.2602 52.2597 12.3473 52.3673 12.3473H54.0568C54.1642 12.3473 54.2515 12.2602 54.2515 12.1528V7.6135C54.2515 6.11795 54.7924 5.32972 55.9922 5.32972C56.2442 5.32972 56.4635 5.36883 56.6566 5.4395C56.6568 5.4395 56.6568 5.43972 56.6568 5.43972L56.8737 5.50995C56.9617 5.53861 57.0575 5.49995 57.1008 5.41795L57.8537 3.99595Z" }),
        React.createElement("path", { d: "M45.0409 6.96261C45.1356 5.75039 45.9311 5.12572 47.0291 5.12572C48.2603 5.12572 48.7905 6.03417 48.7905 6.96261H45.0409ZM47.0291 3.38306C44.4536 3.38306 42.9385 5.01172 42.9385 7.90972C42.9385 11.0717 44.5671 12.4924 47.1998 12.4924C48.7032 12.4924 49.6589 11.9302 50.092 11.5857C50.1827 11.5135 50.1918 11.3839 50.1169 11.2955L49.1905 10.2048C49.1269 10.1297 49.0085 10.1133 48.9247 10.1644C48.492 10.4288 47.944 10.6553 47.1998 10.6553C45.9498 10.6553 44.984 10.0306 44.984 8.66683H49.6616C50.8169 8.66683 50.8169 7.85239 50.8169 7.15195C50.8169 5.8455 50.23 3.38306 47.0291 3.38306V3.38306Z" }),
        React.createElement("path", { d: "M39.682 9.88306C39.682 9.88306 38.9745 10.8079 37.8865 10.8079C36.6534 10.8079 35.946 9.53106 35.946 7.73572C35.946 5.95839 36.6354 5.05128 37.8865 5.05128C38.9383 5.05128 39.682 5.75861 39.682 7.01039V9.88306ZM37.8865 3.38306C35.4383 3.38306 34.0054 4.7795 34.0054 7.73572C34.0054 10.6917 35.4018 12.4766 37.6867 12.4766C38.5574 12.4766 39.4114 12.0204 39.774 11.6577V12.3473C39.774 13.4528 39.0109 14.2175 37.7234 14.2175C37.0463 14.2175 36.4269 13.9037 36.0885 13.6942H36.0883L35.9618 13.6188C35.8703 13.5639 35.7516 13.5973 35.7016 13.6915L35.0334 14.9535C34.9869 15.0413 35.0174 15.1499 35.1027 15.2008L35.4094 15.3839L35.4147 15.3782C36.0165 15.7011 36.7918 15.8857 37.7234 15.8857C40.4076 15.8857 41.6405 14.4713 41.6405 11.9688V7.01039C41.6405 4.59817 40.0809 3.38306 37.8865 3.38306V3.38306Z" }),
        React.createElement("path", { d: "M28.7114 3.38306C27.1396 3.38306 24.8921 4.16017 24.8921 7.33239V12.1528C24.8921 12.2602 24.9792 12.3473 25.0865 12.3473H26.7763C26.8836 12.3473 26.9708 12.2602 26.9708 12.1528V11.9964V7.61372C26.9708 6.11839 27.5119 5.32972 28.7114 5.32972C29.9114 5.32972 30.4523 6.11839 30.4523 7.61372L30.4508 11.4977V12.1528C30.4508 12.2602 30.5381 12.3473 30.6452 12.3473H32.3363C32.4439 12.3473 32.5308 12.2602 32.5308 12.1528V7.33239C32.5308 4.16017 30.2834 3.38306 28.7114 3.38306" }),
        React.createElement("path", { d: "M17.7721 6.96261C17.867 5.75039 18.6624 5.12572 19.7606 5.12572C20.9917 5.12572 21.5219 6.03417 21.5219 6.96261H17.7721ZM19.7606 3.38306C17.1848 3.38306 15.6699 5.01172 15.6699 7.90972C15.6699 11.0717 17.2986 12.4924 19.9313 12.4924C21.4346 12.4924 22.3904 11.9302 22.8235 11.5857C22.9141 11.5135 22.9233 11.3839 22.8484 11.2955L21.9219 10.2048C21.8584 10.1297 21.7397 10.1133 21.6561 10.1644C21.2235 10.4288 20.6755 10.6553 19.9313 10.6553C18.6813 10.6553 17.7155 10.0306 17.7155 8.66683H22.393C23.5481 8.66683 23.5481 7.85239 23.5481 7.15195C23.5481 5.8455 22.9615 3.38306 19.7606 3.38306V3.38306Z" }),
        React.createElement("path", { d: "M15.5652 3.99595C15.6201 3.89217 15.5692 3.76395 15.4578 3.72639L14.9896 3.56839L14.9883 3.57017C14.5487 3.43883 14.1049 3.38306 13.7036 3.38306C12.1316 3.38306 9.88428 4.16017 9.88428 7.33261V12.1531C9.88428 12.2602 9.97117 12.3473 10.0787 12.3473H11.7685C11.8758 12.3473 11.9629 12.2602 11.9629 12.1528V7.6135C11.9629 6.11795 12.5038 5.32972 13.7036 5.32972C13.9556 5.32972 14.1749 5.36883 14.3683 5.4395V5.43972L14.5852 5.50995C14.6732 5.53861 14.7692 5.49995 14.8123 5.41795L15.5652 3.99595Z" }),
        React.createElement("path", { d: "M2.27088 4.15512C2.65732 3.76867 3.46821 3.38289 4.39532 3.38289C7.19977 3.38289 8.1151 5.52578 8.19377 6.91512C8.19977 7.02423 8.10999 7.11156 8.00088 7.11156H6.44377C6.34221 7.11156 6.25777 7.03734 6.2471 6.93645C6.1951 6.43867 5.89755 5.052 4.19866 5.052C2.48021 5.052 2.19555 6.46578 2.14844 6.95089C2.13977 7.04134 2.06555 7.11156 1.97444 7.11156H0.362436C0.264658 7.11156 0.185547 7.03245 0.185547 6.93445V6.17645V0.55245C0.185547 0.324227 0.370436 0.139116 0.598436 0.139116L1.85777 0.138672C2.08577 0.138672 2.27066 0.323561 2.27066 0.551561H2.27088V3.60734V4.15512ZM6.41146 8.73532H7.97301C8.08968 8.73532 8.18457 8.83576 8.17146 8.95198C7.94212 10.9418 6.24857 12.4922 4.19857 12.4922C2.14835 12.4922 0.454569 10.9418 0.22568 8.95198C0.212125 8.83576 0.307014 8.73532 0.423902 8.73532H1.98612C2.07857 8.73532 2.1619 8.79821 2.17968 8.88887C2.36546 9.83487 3.19924 10.5511 4.19857 10.5511C5.19812 10.5511 6.0319 9.83487 6.21768 8.88887C6.23546 8.79821 6.31901 8.73532 6.41146 8.73532Z" })));
};
exports.IconBrengerLogo = IconBrengerLogo;
