"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserDataClient = void 0;
/**
 * This client is slightly unique, in that it does not accepted an Axios instance.
 * Rather, this client's main purpose is to format URLs for client apps that are
 * fetching user data assets directly from our CDN.
 */
var UserDataClient = /** @class */ (function () {
    function UserDataClient(baseUrl) {
        this.baseUrl = baseUrl;
        this.getBaseForIri = this.getBaseForIri.bind(this);
        this.getProfileImageUrl = this.getProfileImageUrl.bind(this);
        this.getJobImageUrl = this.getJobImageUrl.bind(this);
        this.getSignatureImageUrl = this.getSignatureImageUrl.bind(this);
        this.getPhotoProofImageUrl = this.getPhotoProofImageUrl.bind(this);
    }
    UserDataClient.prototype.withParams = function (url, params) {
        var qs = [];
        if (params === null || params === void 0 ? void 0 : params.w) {
            qs.push("w=".concat(params.w));
        }
        if (params === null || params === void 0 ? void 0 : params.h) {
            qs.push("h=".concat(params.h));
        }
        if (qs.length > 0) {
            return "".concat(url, "?").concat(qs.join('&'));
        }
        return url;
    };
    UserDataClient.prototype.getBaseForIri = function (iri, params) {
        return this.withParams("".concat(this.baseUrl).concat(iri), params);
    };
    /**
     * Gets the url string for a profile image.
     */
    UserDataClient.prototype.getProfileImageUrl = function (userId, params) {
        return this.withParams("".concat(this.baseUrl, "/profile_images/").concat(userId), params);
    };
    /**
     * Gets the url string for a job image.
     */
    UserDataClient.prototype.getJobImageUrl = function (id, params) {
        return this.withParams("".concat(this.baseUrl, "/job_images/").concat(id), params);
    };
    /**
     * Gets the url for a signature image.
     */
    UserDataClient.prototype.getSignatureImageUrl = function (id, params) {
        return this.withParams("".concat(this.baseUrl, "/signature_images/").concat(id), params);
    };
    /**
     * Gets the url for photo proof image.
     */
    UserDataClient.prototype.getPhotoProofImageUrl = function (id, params) {
        return this.withParams("".concat(this.baseUrl, "/photo_proof_images/").concat(id), params);
    };
    return UserDataClient;
}());
exports.UserDataClient = UserDataClient;
