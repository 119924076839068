"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserClient = void 0;
var BaseClient_1 = require("../../BaseClient");
var UserClient = /** @class */ (function (_super) {
    __extends(UserClient, _super);
    function UserClient(axiosClient) {
        var _this = _super.call(this, axiosClient) || this;
        _this.isUserLoggedIn = _this.isUserLoggedIn.bind(_this);
        _this.isBrainAlive = _this.isBrainAlive.bind(_this);
        _this.login = _this.login.bind(_this);
        _this.logout = _this.logout.bind(_this);
        _this.requestPasswordReset = _this.requestPasswordReset.bind(_this);
        _this.resetPassword = _this.resetPassword.bind(_this);
        _this.retrieveCurrentUser = _this.retrieveCurrentUser.bind(_this);
        _this.listStopAddressesForCurrentUser = _this.listStopAddressesForCurrentUser.bind(_this);
        _this.listTransportRequestsForCurrentUser = _this.listTransportRequestsForCurrentUser.bind(_this);
        _this.listPreferredRoutesForCurrentUser = _this.listPreferredRoutesForCurrentUser.bind(_this);
        _this.createProfileImageForCurrentUser = _this.createProfileImageForCurrentUser.bind(_this);
        _this.upsertInternalAttributes = _this.upsertInternalAttributes.bind(_this);
        _this.retrieveReviewSummary = _this.retrieveReviewSummary.bind(_this);
        _this.update = _this.update.bind(_this);
        _this.retrieve = _this.retrieve.bind(_this);
        _this.listReviewsForCurrentUser = _this.listReviewsForCurrentUser.bind(_this);
        _this.listReviewsForUser = _this.listReviewsForUser.bind(_this);
        _this.create = _this.create.bind(_this);
        _this.createUserForAdmin = _this.createUserForAdmin.bind(_this);
        _this.retrieveSandboxToken = _this.retrieveSandboxToken.bind(_this);
        _this.retrieveUserExists = _this.retrieveUserExists.bind(_this);
        return _this;
    }
    /**
     * Checks if current user is logged in.
     */
    UserClient.prototype.isUserLoggedIn = function () {
        return __awaiter(this, void 0, void 0, function () {
            var res;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.fetch({ method: 'GET', url: "/am_i_logged_in" })];
                    case 1:
                        res = _a.sent();
                        return [2 /*return*/, res === 'yes'];
                }
            });
        });
    };
    /**
     * Lulz, just a way to check if the backend is alive.
     */
    UserClient.prototype.isBrainAlive = function () {
        return __awaiter(this, void 0, void 0, function () {
            var err_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.fetch({ method: 'GET', url: "/alive", validateStatus: function (status) { return status === 200; } })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/, true];
                    case 2:
                        err_1 = _a.sent();
                        return [2 /*return*/, false];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Retrieves current user's details
     */
    UserClient.prototype.retrieveCurrentUser = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.fetch({ method: 'GET', url: "/users/me" })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Logs user in
     */
    UserClient.prototype.login = function (data) {
        return __awaiter(this, void 0, void 0, function () {
            var _a, trId, email, password, token;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = data, trId = _a.trId, email = _a.email, password = _a.password;
                        token = data.token;
                        return [4 /*yield*/, this.fetch({
                                method: 'POST',
                                url: "/login",
                                data: { email: email, password: password, token: token },
                                params: {
                                    transport_request: trId
                                }
                            })];
                    case 1: return [2 /*return*/, _b.sent()];
                }
            });
        });
    };
    /**
     * Logs user out
     */
    UserClient.prototype.logout = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.fetch({ method: 'GET', url: "/logout" })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Initialize password reset for the given email.
     */
    UserClient.prototype.requestPasswordReset = function (data) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.fetch({ method: 'POST', url: '/users/reset_password/request', data: data })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Create password using a valid token and password val.
     */
    UserClient.prototype.resetPassword = function (data) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.fetch({ method: 'POST', url: '/users/reset_password', data: data })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Retrieves current user's deduped addresses
     */
    UserClient.prototype.listStopAddressesForCurrentUser = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.fetch({ method: 'GET', url: '/users/me/stop_addresses' })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Retrieves current user's transport jobs.
     * If the user is a driver, then it will retrieve the transport requests where the user's offer has been accepted.
     */
    UserClient.prototype.listTransportRequestsForCurrentUser = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.fetch({ method: 'GET', url: '/users/me/transport_requests' })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Add profile image resource to current user.
     */
    UserClient.prototype.createProfileImageForCurrentUser = function (args) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.fetch({
                            method: 'POST',
                            url: '/users/me/profile_image',
                            data: args.imageData,
                            // eslint-disable-next-line @typescript-eslint/naming-convention
                            headers: { 'Content-Type': 'multipart/form-formData' }
                        })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Only the keys included under internal_attributes will be added (if it doesn't exist) or updated (if it exists).
     */
    UserClient.prototype.upsertInternalAttributes = function (_a) {
        var id = _a.id, internalAttributes = __rest(_a, ["id"]);
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, this.fetch({
                            method: 'PUT',
                            url: "/users/".concat(id, "/internal_attribute_keys"),
                            data: internalAttributes
                        })];
                    case 1: return [2 /*return*/, _b.sent()];
                }
            });
        });
    };
    /**
     * Retrieves a summary of a user's all-time and most-recent reviews by customers.
     */
    UserClient.prototype.retrieveReviewSummary = function (args) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.fetch({ method: 'GET', url: "/users/".concat(args.userId, "/review_summary") })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Updates a user resource.
     */
    UserClient.prototype.update = function (_a) {
        var userId = _a.userId, data = __rest(_a, ["userId"]);
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, this.fetch({ method: 'PUT', url: "/users/".concat(userId), data: data })];
                    case 1: return [2 /*return*/, _b.sent()];
                }
            });
        });
    };
    /**
     * Retrieves a user resource.
     */
    UserClient.prototype.retrieve = function (_a) {
        var userId = _a.userId;
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, this.fetch({ method: 'GET', url: "/users/".concat(userId) })];
                    case 1: return [2 /*return*/, _b.sent()];
                }
            });
        });
    };
    /**
     * Lists preferred routes for current user.
     */
    UserClient.prototype.listPreferredRoutesForCurrentUser = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.fetch({ method: 'GET', url: '/users/me/preferred_routes' })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * List the reviews of the logged in user.
     */
    UserClient.prototype.listReviewsForCurrentUser = function (_a) {
        var page = _a.page;
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, this.fetch({ method: 'GET', url: "/reviews", params: { page: page } })];
                    case 1: return [2 /*return*/, _b.sent()];
                }
            });
        });
    };
    /**
     * Fetch the reviews of the provided user.
     * If logged in user is an account admin, reviews can be fetched if the provided user belongs to the account.
     * If logged in user is a normal driver, reviews can be fetched if the provided user is the logged in user.
     */
    UserClient.prototype.listReviewsForUser = function (_a) {
        var userId = _a.userId, page = _a.page;
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, this.fetch({ method: 'GET', url: "/users/".concat(userId, "/reviews"), params: { page: page } })];
                    case 1: return [2 /*return*/, _b.sent()];
                }
            });
        });
    };
    /**
     * Create a new user, this can either be business or normal customer
     */
    UserClient.prototype.create = function (params) {
        return __awaiter(this, void 0, void 0, function () {
            var trId, data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        trId = params.trId, data = __rest(params, ["trId"]);
                        return [4 /*yield*/, this.fetch({
                                method: 'POST',
                                url: "/users",
                                data: data,
                                params: { transport_request: trId }
                            })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * An endpoint for admin users to create new users in the same account.
     */
    UserClient.prototype.createUserForAdmin = function (data) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.fetch({ method: 'POST', url: '/account_admin/users', data: data })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Retrieve auth token for sandbox environment
     */
    UserClient.prototype.retrieveSandboxToken = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.fetch({ method: 'POST', url: '/users/me/generate_sandbox_token', data: {} })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Check if username already exists
     */
    UserClient.prototype.retrieveUserExists = function (username) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.fetch({ method: 'GET', url: "users/exists/".concat(username) })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    return UserClient;
}(BaseClient_1.BaseClient));
exports.UserClient = UserClient;
