const icon = (iconType: 'smile' | 'marker' | 'cross' | 'arrow' | 'marker-new' | 'route' | 'warning'): string => {
  switch (iconType) {
    case 'smile':
      return `<svg class="IconSmile" width="10.66" height="20" viewBox="0 0 8 15" version="1.1"><g><g transform="translate(-722.000000, -31.000000)" class="smile"><g transform="translate(30.000000, 20.000000)"><path fill="#0073FA" d="M692,11.3767916 L692,14.3242916 C692,14.4982916 692.1328,14.6572916 692.328,14.6902916 C694.3408,15.0397916 695.8672,16.6132916 695.8672,18.5002916 C695.8672,20.3857916 694.3408,21.9607916 692.328,22.3102916 C692.1328,22.3447916 692,22.5007916 692,22.6747916 L692,25.6237916 C692,25.8457916 692.2144,26.0242916 692.4624,25.9972916 C696.6992,25.5652916 700,22.3687916 700,18.5002916 C700,14.6317916 696.6992,11.4352916 692.4624,11.0032916 C692.2144,10.9777916 692,11.1577916 692,11.3767916"></path></g></g></g></svg>`;
    case 'marker-new':
      return `<svg class="IconMarker" width="17" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.08798 10.1639C9.20007 10.1639 10.11 9.24914 10.11 8.13111C10.11 7.01309 9.20007 6.09834 8.08798 6.09834C6.97588 6.09834 6.06598 7.01309 6.06598 8.13111C6.06598 9.24914 6.97588 10.1639 8.08798 10.1639ZM8.08798 0C12.3342 0 16.176 3.27277 16.176 8.33439C16.176 11.5665 13.699 15.3678 8.75523 19.7484C8.37106 20.0839 7.79479 20.0839 7.41061 19.7484C2.47694 15.3678 0 11.5665 0 8.33439C0 3.27277 3.84179 0 8.08798 0Z" fill="#0073FA"/>
</svg>`;
    case 'marker':
      return `<svg class="IconMarkerNew" width="16px" height="20px" viewBox="0 0 16 20"><g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g transform="translate(-472.000000, -698.000000)" fill="#0073FA"><g transform="translate(140.000000, 625.000000)"><g transform="translate(30.000000, 20.000000)"><g transform="translate(282.000000, 38.000000)"><path d="M24.335181,22.9288603 C24.335181,20.8148118 25.9834419,19.1011383 28.0164378,19.1011383 C30.0494337,19.1011383 31.6976946,20.8148118 31.6976946,22.9288603 C31.6976946,25.0429088 30.0494337,26.7565823 28.0164378,26.7565823 C25.9834419,26.7565823 24.335181,25.0429088 24.335181,22.9288603 Z M28.0132437,15 C23.806093,15 20.3089609,18.4996652 20.0197399,22.9835007 C19.9408146,24.1319138 20.0985622,25.2528994 20.4929825,26.3738851 C20.8612319,27.4401232 21.492222,28.4517209 22.3073368,29.381358 L27.7239196,34.8631847 C27.8028449,34.9452524 27.9079412,35 28.0132437,35 C28.11834,35 28.2498135,34.9452524 28.3023616,34.8631847 C28.3549098,34.7811169 33.6926703,29.381358 33.6926703,29.381358 C34.5341623,28.4517209 35.1388783,27.4401232 35.5070246,26.3738851 C35.901342,25.2528994 36.0591925,24.1319138 35.9802672,22.9835007 C35.7173203,18.4996652 32.2202912,15 28.0132437,15 Z" id="icon-naar"></path></g></g></g></g></g></svg>`;
    case 'cross':
      return `<svg class="IconCross" width="20" height="20" viewBox="0 0 27.85 27.83" version="1.1"><path d="M16.53,13.93l10.8-10.8a1.87,1.87,0,0,0,0-2.6,1.87,1.87,0,0,0-2.6,0l-10.8,10.8L3.13.52a1.87,1.87,0,0,0-2.6,0,1.87,1.87,0,0,0,0,2.6l10.8,10.8L.53,24.73a1.87,1.87,0,0,0,0,2.6,1.66,1.66,0,0,0,1.3.5,1.9,1.9,0,0,0,1.3-.5l10.8-10.8,10.8,10.8a1.66,1.66,0,0,0,1.3.5,1.9,1.9,0,0,0,1.3-.5,1.87,1.87,0,0,0,0-2.6Z"></path></svg>`;
    case 'arrow':
      return `<svg class="IconArrow" viewBox="0 0 30 25" width="30" height="25"><g><path d=" M 29.69 11.738 L 19.04 0.368 C 18.61 -0.095 17.88 -0.125 17.41 0.299 C 16.94 0.723 16.91 1.442 17.34 1.905 L 26.53 11.709 L 1.15 11.709 C 0.51 11.709 0 12.221 0 12.842 C 0 13.472 0.52 13.975 1.15 13.975 L 25.88 13.975 L 17.34 23.089 C 16.91 23.552 16.94 24.271 17.41 24.695 C 17.63 24.901 17.91 25 18.19 25 C 18.5 25 18.81 24.882 19.04 24.635 L 29.69 13.265 C 30.1 12.842 30.1 12.172 29.69 11.738 Z " class="arrow-shape"></path></g></svg>`;
    case 'route':
      return `<svg class="route" width="12" height="70" viewBox="0 0 12 70" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6 3L6 60" stroke="#006BF3" stroke-width="4"/><ellipse cx="5.96817" cy="64" rx="5.96817" ry="6" fill="#006BF3"/><ellipse cx="5.96817" cy="64" rx="5.96817" ry="6" fill="#006BF3"/><ellipse cx="5.96817" cy="6" rx="5.96817" ry="6" fill="#006BF3"/><ellipse cx="5.96817" cy="6" rx="5.96817" ry="6" fill="#006BF3"/><ellipse cx="5.9684" cy="6" rx="1.98939" ry="2" fill="#006BF3"/><ellipse cx="5.9684" cy="6" rx="1.98939" ry="2" fill="white"/></svg>`;
    case 'warning':
      return `<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="9" cy="9" r="8" stroke="#F15B56" stroke-width="1.6"/><path fill-rule="evenodd" clip-rule="evenodd" d="M8.99998 4.82611V10.3913V4.82611Z" fill="#F15B56"/><path d="M8.99998 4.82611V10.3913" stroke="#F15B56" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/><path fill-rule="evenodd" clip-rule="evenodd" d="M8.99998 12.4783V13.1739V12.4783Z" fill="#F15B56"/><path d="M8.99998 12.4783V13.1739" stroke="#F15B56" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/></svg>`;
    default:
      return `<div></div>`;
  }
};
export default icon;
