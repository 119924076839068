"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AccountingClient = void 0;
var Vat_1 = require("./clients/Vat");
var AccountingClient = /** @class */ (function () {
    function AccountingClient(axiosInstance) {
        this.vat = new Vat_1.VatClient(axiosInstance);
    }
    return AccountingClient;
}());
exports.AccountingClient = AccountingClient;
