"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.IconSmile = void 0;
var React = __importStar(require("react"));
var IconSmile = function (props) { return (React.createElement("svg", __assign({ width: ".5em", height: "1em", viewBox: "0 0 8 15", version: "1.1", fill: "currentColor" }, props),
    React.createElement("g", null,
        React.createElement("g", { transform: "translate(-722.000000, -31.000000)", className: "smile" },
            React.createElement("g", { transform: "translate(30.000000, 20.000000)" },
                React.createElement("path", { d: "M692,11.3767916 L692,14.3242916 C692,14.4982916 692.1328,14.6572916 692.328,14.6902916 C694.3408,15.0397916 695.8672,16.6132916 695.8672,18.5002916 C695.8672,20.3857916 694.3408,21.9607916 692.328,22.3102916 C692.1328,22.3447916 692,22.5007916 692,22.6747916 L692,25.6237916 C692,25.8457916 692.2144,26.0242916 692.4624,25.9972916 C696.6992,25.5652916 700,22.3687916 700,18.5002916 C700,14.6317916 696.6992,11.4352916 692.4624,11.0032916 C692.2144,10.9777916 692,11.1577916 692,11.3767916" })))))); };
exports.IconSmile = IconSmile;
