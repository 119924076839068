"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.IconBrengerB = void 0;
var React = __importStar(require("react"));
var IconBrengerB = function (props) {
    return (React.createElement("svg", __assign({ width: ".6em", height: "1em", viewBox: "0 0 15 22", fill: "currentColor" }, props),
        React.createElement("path", { d: "M7.50151 5.66747C5.84942 5.66747 4.40488 6.34148 3.72769 7.0155V0.721277C3.72714 0.53015 3.64943 0.347004 3.51154 0.211856C3.37366 0.0767073 3.1868 0.00054221 2.9918 0H0.735895C0.540724 0 0.353547 0.0759911 0.21554 0.211257C0.0775327 0.346522 0 0.529983 0 0.721277V11.8692C0.000553647 11.9512 0.0341528 12.0296 0.0934635 12.0873C0.152774 12.1451 0.232981 12.1775 0.316582 12.1775H3.18468C3.26269 12.1778 3.33801 12.1495 3.39581 12.0982C3.45362 12.0468 3.48974 11.9762 3.49707 11.9C3.58093 11.0575 4.08411 8.57929 7.15139 8.57929C10.1704 8.57929 10.7155 10.9877 10.8015 11.8672C10.8104 11.9536 10.8518 12.0338 10.9177 12.0919C10.9836 12.15 11.0693 12.182 11.1579 12.1816H13.9107C13.9596 12.1816 14.008 12.1719 14.0529 12.153C14.0979 12.1341 14.1384 12.1063 14.1719 12.0715C14.2055 12.0367 14.2315 11.9955 14.2482 11.9504C14.2649 11.9054 14.2721 11.8575 14.2692 11.8096C14.1183 9.37866 12.4788 5.66747 7.50151 5.66747Z" }),
        React.createElement("path", { d: "M13.9119 15.3202H11.1129C11.0319 15.3193 10.953 15.3458 10.8895 15.3953C10.826 15.4447 10.7818 15.5141 10.7644 15.5918C10.6014 16.4194 10.1498 17.1655 9.48718 17.7017C8.82458 18.2379 7.99238 18.5309 7.13361 18.5301C6.27485 18.5294 5.44317 18.235 4.78152 17.6977C4.11988 17.1603 3.6696 16.4135 3.50809 15.5856C3.49106 15.5081 3.44728 15.4387 3.38416 15.3892C3.32103 15.3397 3.24243 15.3132 3.16164 15.314H0.358413C0.308741 15.3138 0.259576 15.3238 0.214059 15.3433C0.168542 15.3628 0.127675 15.3915 0.0940743 15.4274C0.0604732 15.4633 0.0348764 15.5057 0.0189202 15.5518C0.00296399 15.598 -0.00299989 15.6469 0.00140932 15.6955C0.205832 17.4118 1.04533 18.9947 2.36045 20.1434C3.67557 21.2922 5.37472 21.9268 7.13517 21.9268C8.89563 21.9268 10.5948 21.2922 11.9099 20.1434C13.225 18.9947 14.0645 17.4118 14.2689 15.6955C14.2724 15.6474 14.2658 15.5992 14.2494 15.5538C14.233 15.5083 14.2073 15.4667 14.1738 15.4315C14.1403 15.3963 14.0997 15.3682 14.0547 15.349C14.0096 15.3299 13.961 15.3201 13.9119 15.3202Z" })));
};
exports.IconBrengerB = IconBrengerB;
