"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.IconLock = void 0;
var React = __importStar(require("react"));
var IconLock = function (props) { return (React.createElement("svg", __assign({ xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 16 21", fill: "currentColor", width: ".8em", height: "1em" }, props),
    React.createElement("path", { d: "M14.1969 7.80694H12.7503L12.7501 4.78634C12.7501 2.14731 10.619 0 7.9999 0C5.38079 0 3.24968 2.14731 3.24968 4.78634V7.80709L1.80294 7.80729C0.808793 7.80729 0 8.62223 0 9.62394V19.1833C0 20.1851 0.808793 21 1.80294 21H14.1969C15.191 21 15.9998 20.1851 15.9998 19.1833L16 9.62344C16 8.62173 15.1912 7.80659 14.1971 7.80659L14.1969 7.80694ZM4.0815 4.78634C4.0815 2.60958 5.83937 0.838301 7.99975 0.838301C10.1601 0.838301 11.918 2.60953 11.918 4.78634V7.80709H4.0817L4.0815 4.78634ZM15.1672 19.1831C15.1672 19.7226 14.7317 20.1615 14.1963 20.1615L1.80234 20.1613C1.26693 20.1613 0.831375 19.7224 0.831375 19.1829L0.831569 9.6235C0.831569 9.08401 1.26712 8.64515 1.80254 8.64515H14.1965C14.7319 8.64515 15.1674 9.08401 15.1674 9.6235L15.1672 19.1831ZM7.99965 11.9725C7.22821 11.9725 6.60051 12.605 6.60051 13.3823C6.60051 14.0128 7.01613 14.5414 7.58366 14.7211V16.6957C7.58366 16.9271 7.77 17.1148 7.99967 17.1148C8.22935 17.1148 8.41568 16.9271 8.41568 16.6957V14.7211C8.98319 14.5413 9.39883 14.0128 9.39883 13.3823C9.39883 12.6046 8.77113 11.9725 7.99969 11.9725H7.99965ZM7.99965 13.9534C7.68697 13.9534 7.43273 13.697 7.43273 13.3821C7.43273 13.0671 7.68718 12.8109 7.99965 12.8109C8.31234 12.8109 8.56657 13.0673 8.56657 13.3821C8.56657 13.697 8.31232 13.9534 7.99965 13.9534Z" }))); };
exports.IconLock = IconLock;
