import { NavItem } from '../interface';

export const navListMarkup = (navItems: NavItem[]): string => {
  return `<div>
            <div id="brenger-burger" class="main-nav--toggle">
                <div class="main-nav--toggle-hamburger"></div>
                <div class="main-nav--toggle-hamburger"></div>
                <div class="main-nav--toggle-hamburger"></div>
            </div>
            <nav class="main-nav--items">
                <ul class="main-nav--items-desktop">
                    ${renderList(navItems)}
                </ul>
                <div class="main-nav--filler"></div>
            </nav>
        </div>`;
};

const renderList = (navItems: NavItem[], isSubNav = false): string => {
  const rendered = navItems
    .map(element => {
      return renderNavItem(element);
    })
    .join('');
  if (isSubNav) {
    return `
    <ul class='sub-nav'>
          ${rendered}
      </ul>
    `;
  }
  return rendered;
};

const renderNavItem = (item: NavItem): string => {
  return `<li class='nav-item ${item.children ? ' nav-item--has-sub' : ''}${item.image ? ' nav-item--has-image ' : ''}${
    item.extra_classes ? '' + item.extra_classes : ''
  }'>
          <${item.children ? 'span' : 'a'} class='brenger-menu-link ${
            item.extra_link_classes ? ' ' + item.extra_link_classes : ''
          }' ${item.children ? '' : "href='" + item.url + "'"}>
            ${item.title} ${
              item.image ? "<span class='nav-item--image' style='background-image:url(" + item.image + ")'></span>" : ''
            }
            ${item.children ? renderList(item.children, true) : ''}
          </${item.children ? 'span' : 'a'}>
        </li>`;
};
