/* eslint-disable */
export const Config = Object.freeze({
  NODE_ENV: process.env.NODE_ENV,
  API_URL: process.env.API_URL || 'https://api.brenger.nl',
  API_TOKEN: process.env.API_TOKEN,
  GEO_API_URL: process.env.GEO_API_URL || 'https://geo.brenger.nl',
  BRENGER_BASE_URL: process.env.BRENGER_BASE_URL || 'https://www.brenger.nl',
  BRENGER_MAIN_DOMAIN: 'https://www.brenger.nl',
  BRENGER_DRIVER_BASE_URL: process.env.BRENGER_DRIVER_BASE_URL || 'https://driver.brenger.nl',
  TRANSLATIONS_URL: 'https://api.brenger.nl',
  SENTRY_DSN: process.env.SENTRY_DSN,
  LOG: process.env.LOG === 'true',
  TRIGGER_CONSENT_GTM: process.env.TRIGGER_CONSENT_GTM === 'true',
  PRICE_API_URL: process.env.PRICE_API_URL || 'https://pricing.brenger.nl',
  ASSETS_PATH: process.env.ASSETS_PATH,
  TRACKING_BASE_URL: process.env.TRACKING_BASE_URL || 'https://live.brenger.nl',
  COMMIT_SHA: process.env.CI_COMMIT_SHA, // provided by gitlab CI
  SUPPORTED_LANGUAGES: ['nl', 'en', 'en-nl', 'de-de', 'es-es'],
  ALGOLIA_SEARCH_API_KEY: process.env.ALGOLIA_SEARCH_API_KEY,
  USER_DATA_URL: process.env.USER_DATA_URL || 'https://user-data.brenger.nl',
  TB_AUCTIONS_GRAPHQL_ENDPOINT:
    process.env.TB_AUCTIONS_GRAPHQL_ENDPOINT || 'https://storefront.tbauctions.com/storefront/graphql',
});
