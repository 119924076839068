export interface AbTest {
  eid: string;
  variation: string;
  conversion: number;
}

export const getAbTestData = (): AbTest[] => {
  // Current tool is installed in WordPress, it sets a cookie if there is an experiment active
  try {
    // get all cookies as object
    const cookies = Object.fromEntries(
      document.cookie.split('; ').map(c => {
        const [key, ...v] = c.split('=');
        return [key, v.join('=')];
      })
    );
    // Regex for cookie key that indicates experiment
    const reg = new RegExp(/(btab_).\d+$/);

    // Gather an array of AbTest objects
    const result: AbTest[] = [];
    Object.keys(cookies).forEach(key => {
      if (reg.test(key)) {
        const decoded = decodeURIComponent(cookies[key]);
        if (!decoded) return;
        result.push(JSON.parse(decoded));
      }
    });
    return result;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
    return [];
  }
};
