import { widgetAddressWhiteList } from '../global';
import { GeoAutocompleteAddress, GeoLocationDetails } from '@brenger/api-client';

export const getFilteredAddress = (addressRaw: GeoLocationDetails): GeoAutocompleteAddress => {
  const address = {};
  widgetAddressWhiteList.forEach(key => {
    if (addressRaw.address[key]) {
      address[key] = addressRaw.address[key];
    }
  });
  return address as GeoAutocompleteAddress;
};
