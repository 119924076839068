import autocomplete from 'autocompleter';
import { InputClasses, ResetFieldsAndValues } from '../interface';
import { getStoredAutocompleteResults, reHydrate, storeItem } from '../helpers/storage';
import { callAutocomplete, fetchPlaceDetails } from './fetchResults';
import { translate } from '../helpers/translations';
import { getFilteredAddress } from '../helpers/getFilteredAddress';
import { uuidv4 } from '../../../src/utils/eventTracking';
import { trackEvent, TrackingStorageKeys, getTimeInRoundedSeconds } from '../helpers/track';
import { GeoAutocomplete } from '@brenger/api-client';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const _AUTOCOMPLETE_SELECTED = 'BRENGER_AUTOCOMPLETE_SELECTED';

export const reHydrateInputs = (elements: NodeListOf<HTMLInputElement>): void => {
  for (let i = 0; i < elements.length; i++) {
    // when already have value, don't rehydrate
    if (!elements[i].value) {
      reHydrate(elements[i]);
    }
  }
};

const initAutocompleteInputs = (elements: NodeListOf<HTMLInputElement>): void => {
  reHydrateInputs(elements);

  for (let i = 0; i < elements.length; i++) {
    const parent = elements[i].closest(`.${InputClasses.AUTO_COMPLETE_WRAPPER}`);
    if (parent && parent.classList.contains(InputClasses.AUTO_COMPLETE_WRAPPER_WIDGET)) {
      attachListeners(elements[i]);
      attachAutocomplete(elements[i]);
      firstInteraction(elements[i]);
    }
    attachListeners(elements[i]);
  }
  const clearButtons = document.querySelectorAll(`.${InputClasses.CLEAR}`);
  for (let i = 0; i < clearButtons.length; i++) {
    clearButtons[i].addEventListener('click', () => {
      const parent = clearButtons[i].closest(`.${InputClasses.AUTO_COMPLETE}`);
      if (!parent) {
        return;
      }
      const input = parent.querySelector(`.${InputClasses.AUTO_COMPLETE_INPUT}`) as HTMLInputElement;
      if (!input) {
        return;
      }
      const name = input.getAttribute('name');
      if (!name) {
        return;
      }
      parent.classList.remove(InputClasses.AUTO_COMPLETE_INPUT_SELECTED);
      localStorage.removeItem(name);
      const inputsWithSameName: NodeListOf<HTMLInputElement> = document.querySelectorAll(
        `.${InputClasses.AUTO_COMPLETE_WRAPPER} [name=${name}]`
      );
      for (let index = 0; index < inputsWithSameName.length; index++) {
        inputsWithSameName[index].value = '';
        inputsWithSameName[index].dispatchEvent(new Event('change'));
      }
      input.focus();
    });
  }
  const feedbackAndLabels = document.querySelectorAll(
    `.${InputClasses.FEEDBACK}, .${InputClasses.AUTO_COMPLETE_INPUT_WRAPPER} label`
  );
  for (let i = 0; i < feedbackAndLabels.length; i++) {
    feedbackAndLabels[i].addEventListener('click', () => {
      const parent = feedbackAndLabels[i].closest(`.${InputClasses.AUTO_COMPLETE}`);
      if (!parent) {
        return;
      }
      const input = parent.querySelector(`.${InputClasses.AUTO_COMPLETE_INPUT}`) as HTMLInputElement;
      if (!input) {
        return;
      }
      input.focus();
    });
  }
};
export default initAutocompleteInputs;

const minSearchCharacterLength = 3;

// token used to link a session of autocomplete requests with place details lookup for Google Maps billing
// optimization
export const GooglePlacesSessionToken = uuidv4();

export const attachAutocomplete = (el: HTMLInputElement): void => {
  const name = el.getAttribute('name');
  const parent = el.closest(`.${InputClasses.BASE}`) as HTMLElement;
  if (!name || !parent) {
    return;
  }

  autocomplete({
    minLength: minSearchCharacterLength,
    emptyMsg: translate('widget.no_matches'),
    debounceWaitMs: 200,
    showOnFocus: true,
    input: el,
    render: (item: GeoAutocomplete) => {
      const div = document.createElement('div');
      div.innerHTML = item.label_html;
      return div;
    },
    customize: (input, inputRect, container) => {
      const zeroResultsContainer = container.querySelector('div.empty');
      // if has result
      if (zeroResultsContainer === null && container.querySelectorAll('div').length > 0) {
        parent.classList.add(InputClasses.HAS_LIST);
        parent.classList.remove(InputClasses.HAS_FEEDBACK);
        // else zero result
      } else {
        parent.classList.add(InputClasses.HAS_FEEDBACK);
        if (zeroResultsContainer) {
          zeroResultsContainer.remove();
          const zeroResultsEl = parent.querySelector(`.${InputClasses.AUTO_COMPLETE_ZERO_RESULT}`);
          if (zeroResultsEl) {
            zeroResultsEl.innerHTML = `
          <div class="text--bold">${translate('widget.no_matches_title').replace(
            '${search_term}',
            `<i>${input.value}</i>`
          )}</div>
          ${translate('widget.no_matches_body')}
          `;
          }
        }
      }
      container.removeAttribute('style');
      container.classList.add(InputClasses.AUTO_COMPLETE_LIST);
      const list = parent.querySelector(`.${InputClasses.AUTO_COMPLETE_LIST_CONTAINER}`);
      if (list) {
        list.innerHTML = '';
        list.appendChild(container);
      }
    },
    fetch: (text, callback) => {
      if (text.length < minSearchCharacterLength) {
        parent.classList.remove(InputClasses.HAS_FEEDBACK);
        return;
      }
      callAutocomplete({
        text,
        callback,
        inputParent: parent,
        name,
      });
    },
    onSelect: (item: GeoAutocomplete) => handleItemSelect(item, name, parent),
  });
};

export const firstInteraction = (el: HTMLInputElement): void => {
  el.addEventListener('click', () => {
    /*
     * Save the the time that first interaction is triggered
     */
    // Check if we already handled timing
    const startTime = sessionStorage.getItem(TrackingStorageKeys.FIRST_INTERACTION_TIMESTAMP);
    if (startTime) {
      // bail here, tracking + setting time has already happend
      return;
    }
    const now = getTimeInRoundedSeconds();
    const domReadyTime = sessionStorage.getItem(TrackingStorageKeys.DOM_READY_TIMESTAMP);
    /* We track how long it took before the user interacted with the widget, based on dom ready timer */
    if (!domReadyTime) {
      return;
    }

    // get the diff in seconds
    const diffInStepsOfFiveSeconds = now - parseInt(domReadyTime);
    // Fire tracking for first interaction
    trackEvent({
      eventCategory: 'Widget interaction',
      eventAction: 'First interaction',
      eventLabel: String(diffInStepsOfFiveSeconds),
    });
    // Save starting time of first interaction, this will be used for the "first interaction > complete" tracking
    sessionStorage.setItem(TrackingStorageKeys.FIRST_INTERACTION_TIMESTAMP, String(now));
  });
};

const handleItemSelect = async (item: GeoAutocomplete, name: string, parent: HTMLElement): Promise<void> => {
  trackEvent({
    eventCategory: 'Widget interaction',
    eventAction: 'Select',
    eventLabel: item.label,
  });
  showSelectedItem(name, item);
  const address = await fetchPlaceDetails(item);
  if (address) {
    storeItem(name, address);
  }
  setTimeout(() => {
    parent.classList.remove(InputClasses.HAS_LIST, InputClasses.HAS_FEEDBACK);
    parent.classList.add(InputClasses.AUTO_COMPLETE_INPUT_SELECTED);
  }, 50);
  if (address) {
    const event = new CustomEvent(_AUTOCOMPLETE_SELECTED, {
      detail: {
        addressDetails: getFilteredAddress(address),
        name,
      },
    });
    document.dispatchEvent(event);
  }
  localStorage.setItem(ResetFieldsAndValues.STORAGE_KEY, ResetFieldsAndValues.FULL);
};

export const showSelectedItem = (name: string, selectedItem: GeoAutocomplete): void => {
  if (!selectedItem.label) {
    return;
  }
  const inputs: NodeListOf<HTMLInputElement> = document.querySelectorAll(`[name=${name}]`);
  for (let i = 0; i < inputs.length; i++) {
    inputs[i].value = selectedItem.label;
    const parent = inputs[i].closest(`.${InputClasses.BASE}`);
    if (!parent) {
      return;
    }
    parent.classList.add(InputClasses.HAS_VALUE, InputClasses.AUTO_COMPLETE_INPUT_SELECTED);
  }
};

const attachListeners = (el: HTMLInputElement): void => {
  const parent = el.closest(`.${InputClasses.BASE}`);
  if (!parent) {
    return;
  }

  const handleInputValueClasses = (event): void => {
    if (!parent) {
      return;
    }
    if (event.target.value.length < 4) {
      parent.classList.remove(InputClasses.HAS_LIST, InputClasses.AUTO_COMPLETE_INPUT_SELECTED);
      localStorage.removeItem(el.getAttribute('name') || '');
    }
    if (event.target.value.length === 0) {
      parent.classList.remove(InputClasses.HAS_VALUE);
      return;
    }
    parent.classList.add(InputClasses.HAS_VALUE);
  };

  el.addEventListener('input', handleInputValueClasses);
  el.addEventListener('change', handleInputValueClasses);
  el.addEventListener('paste', handleInputValueClasses);
  el.addEventListener('keydown', event => {
    if (event.code === 'Enter') {
      event.preventDefault();
    }
    if (event.code === 'Tab') {
      const selectedItem = parent.querySelector(
        `.${InputClasses.AUTO_COMPLETE_LIST} .${InputClasses.AUTO_COMPLETE_SELECTED}`
      );
      if (!selectedItem) {
        return;
      }
      selectedItem.dispatchEvent(new Event('click'));
    }
  });
  el.addEventListener('focus', () => {
    parent.classList.add(InputClasses.HAS_FOCUS);
    parent.classList.remove(InputClasses.SHOW_ZERO_RESULT);

    // This scroll trick is only needed for Android
    // Old-fashioned indexOf is still more performent then includes when comparing strings
    if (navigator.userAgent.toLowerCase().indexOf('android') === -1) {
      return;
    }
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    window.scrollTo({
      top: yCoordinate - 100,
      behavior: 'smooth',
    });
  });
  el.addEventListener('blur', () => {
    parent.classList.remove(InputClasses.HAS_FOCUS);
    autoSelectFirstOptions(el);
    setTimeout(() => {
      parent.classList.remove(InputClasses.HAS_LIST);
    }, 50);
  });
};

const autoSelectFirstOptions = async (el: HTMLInputElement): Promise<void> => {
  const name = el.getAttribute('name');
  const parent = el.closest(`.${InputClasses.BASE}`) as HTMLElement;
  // we need a name, a parent, a minimum value and a check if there has been a selection
  if (!name || !parent || el.value.length < minSearchCharacterLength || localStorage.getItem(name) !== null) {
    return;
  }
  // Get stored results, select first result if present
  const storedResults = getStoredAutocompleteResults(name);
  if (storedResults.length > 0) {
    await handleItemSelect(storedResults[0], name, parent);
  }
};
