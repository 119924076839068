"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.IconAddStop = void 0;
var React = __importStar(require("react"));
var IconAddStop = function (props) { return (React.createElement("svg", __assign({ width: "1em", height: "1em", viewBox: "0 0 17 17", fill: "currentColor", xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M8.76424 0.400391C4.2251 0.400391 0.532715 3.92398 0.532715 8.25562C0.532715 12.5873 4.2251 16.1109 8.76424 16.1109C13.3034 16.1109 16.9958 12.5866 16.9958 8.25562C16.9958 3.9246 13.3034 0.400391 8.76424 0.400391ZM8.76424 14.8939C4.92903 14.8939 1.80793 11.9161 1.80793 8.25562C1.80793 4.59511 4.92903 1.61731 8.76424 1.61731C12.5995 1.61731 15.7206 4.59511 15.7206 8.25562C15.7206 11.9161 12.6001 14.8939 8.76424 14.8939Z" }),
    React.createElement("path", { d: "M11.9532 7.59236H9.40274V5.15852C9.40274 4.82265 9.11773 4.55005 8.76512 4.55005C8.41251 4.55005 8.1275 4.82265 8.1275 5.15852V7.59236H5.57708C5.22447 7.59236 4.93945 7.86496 4.93945 8.20083C4.93945 8.5367 5.22447 8.8093 5.57708 8.8093H8.1275V11.2431C8.1275 11.579 8.41251 11.8516 8.76512 11.8516C9.11773 11.8516 9.40274 11.579 9.40274 11.2431V8.8093H11.9532C12.3058 8.8093 12.5908 8.5367 12.5908 8.20083C12.5908 7.86496 12.3058 7.59236 11.9532 7.59236Z" }))); };
exports.IconAddStop = IconAddStop;
