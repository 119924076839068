import * as Sentry from '@sentry/browser';
import { Integration } from '@sentry/types';
import { ExtraErrorData as ExtraErrorDataIntegration } from '@sentry/integrations';
import { Config } from '../config';
import { getCustomerBaseUrl } from './global';

export const ignoredErrors = new RegExp(
  `TypeError: NetworkError when attempting to fetch resource|TypeError: Failed to fetch|500 Internal Server Error|401 Unauthorized|403 Forbidden|403 FAILED REQUEST|404 Not Found|502 Bad Gateway|503 Service Unavailable|cannot fetch the geo location based on IP|Object Not Found Matching Id:2`
);

export const beforeSendFilter = (event: Sentry.ErrorEvent, hint: Sentry.EventHint): Sentry.ErrorEvent | null => {
  /**
   * BIG FAT NOTE:
   * I have added today a list of ip-addresses that need to be ignored by Sentry for the widget project only.
   * It is a range of ip-addresses that belong to Hetzner, famous for crawlers and such.
   * ref: https://brenger.sentry.io/issues/4631778387/?project=5620968&query=is%3Aunresolved&referrer=issue-stream&statsPeriod=14d&stream_index=4
   * manage ip address at "widget" project settings > Inbound filters
   */
  /**
   * Usually these rejections flag why it is unhandled. Think of "value: Timeout" or "failed with keys: keyslist".
   * In sentry we suddenly experiencing some without any value (all windows 10, chrome), suspecting it is a bug in the browser
   * So this removes the noise.
   */
  const error = hint?.originalException;
  const errorMessage = (typeof error === 'string' ? error : (error as Error)?.message || '').trim();
  const nonInformativeMessage = 'Non-Error promise rejection captured with value:';
  if (errorMessage && errorMessage.substr(-nonInformativeMessage.length) === nonInformativeMessage) {
    return null;
  }
  return event;
};

export const initSentry = (): void => {
  const isCustomerAppPath = window.location.href.includes(getCustomerBaseUrl());
  const isLocal = window.location.hostname.includes('localhost');
  if (isLocal || isCustomerAppPath || !['production', 'staging'].includes(Config.NODE_ENV || '')) {
    // static site is loading the widget as well, which is useless || the customer app loads it's own sentry || we are only interested in production and staging errors
    return;
  }
  Sentry.init({
    environment: Config.NODE_ENV,
    dsn: Config.SENTRY_DSN,
    release: Config.COMMIT_SHA,
    allowUrls: [/(brenger)(-transport)?\.(nl|be|com)/], // Only track exceptions from files that are served from this domain
    integrations: [
      new ExtraErrorDataIntegration({
        // limit of how deep the object serializer should go. Anything deeper than limit will
        // be replaced with standard Node.js REPL notation of [Object], [Array], [Function] or
        // a primitive value. Defaults to 3.
        depth: 3,
      }) as Integration,
    ],
    tracesSampleRate: 1.0, // lower this to consume a percentage of transactions
    ignoreErrors: [
      ignoredErrors,
      // Attempt to ignore freshchat
      'with keys: data, status, success',
      // Error occurs with tag-manager, probably by a client script,
      // Or this is the reason but no solution yet: https://github.com/getsentry/sentry-javascript/issues/9110
      "undefined is not an object (evaluating 'a.L')",
      'SecurityError: Blocked a frame with origin "https://www.brenger.nl" from accessing a cross-origin frame',
    ],
    denyUrls: [
      /script\.hotjar\.com/,
      /ct\.pinterest\.com/,
      /wchat\.freshchat\.com/,
      /maps\.googleapis\.com/,
      /\/subscribe_newsletter/,
    ],
    beforeSend: beforeSendFilter,
  });
};

export const logException = (message: string, data?: Record<string, unknown>): void => {
  if (Config.NODE_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.log(`[SENTRY] ${message}`, data);
    return;
  }
  Sentry.withScope((scope: Sentry.Scope) => {
    data && scope.setExtras(data);
    Sentry.captureMessage(message);
  });
};
