"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.IconStarOutline = void 0;
var React = __importStar(require("react"));
var IconStarOutline = function (props) { return (React.createElement("svg", __assign({ xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 16 16", width: "1em", height: "1em", fill: "none", stroke: "currentColor" }, props),
    React.createElement("path", { d: "M5.67071 6.09497C5.84345 6.0703 5.99083 5.95735 6.05957 5.79696L8 1.2693L9.94043 5.79696C10.0092 5.95735 10.1565 6.0703 10.3293 6.09497L14.9393 6.75355L11.6464 10.0464C11.5352 10.1577 11.4828 10.3149 11.505 10.4707L12.1701 15.126L8.22361 13.1528C8.08284 13.0824 7.91716 13.0824 7.77639 13.1528L3.82993 15.126L4.49497 10.4707C4.51723 10.3149 4.46484 10.1577 4.35355 10.0464L1.06066 6.75355L5.67071 6.09497Z" }))); };
exports.IconStarOutline = IconStarOutline;
