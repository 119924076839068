// enums

export enum FormClasses {
  MAIN = 'form.brenger-tr-widget--wrapper',
}
export enum Paths {
  GENERAL_FLOW = '/customer/transport_request',
  NEXT_PATH = '/transport_request/pickup',
  LOGOUT = '/logout',
}

export enum CustomEvents {
  USER_UPDATED = 'BRENGER_USER_UPDATED',
  WIDGET_RENDER = 'BRENGER_WIDGET_RENDER',
  WIDGET_INIT = 'BRENGER_WIDGET_INIT',
  WIDGET_RESET = 'BRENGER_WIDGET_RESET',
  WIDGET_FOCUS = 'BRENGER_WIDGET_FOCUS',
  WIDGET_INTERNAL_SUBMIT = 'BRENGER_WIDGET_SUBMIT',
  LANGUAGE_UPDATE = 'BRENGER_LANGUAGE_UPDATE',
}

export enum WidgetFields {
  PICKUP = 'location_autocomplete_general_pickup',
  DELIVERY = 'location_autocomplete_general_delivery',
}

export enum AccountType {
  INDIVIDUAL = 'individual',
  BUSINESS = 'business',
}

export enum ResetFieldsAndValues {
  STORAGE_KEY = 'brenger_reset_flow',
  PARAM_KEY = 'reset_flow',
  FULL = 'full',
  DATE = 'date_only',
}

export enum InputClasses {
  BASE = 'input-el',
  AUTO_COMPLETE = 'input-el--autocomplete',
  AUTO_COMPLETE_INPUT = 'input-el--autocomplete--input',
  AUTO_COMPLETE_INPUT_SELECTED = 'input-el--autocomplete--selected',
  AUTO_COMPLETE_LOADING = 'input-el--autocomplete--is-loading',
  AUTO_COMPLETE_LIST = 'input-el--autocomplete--list',
  AUTO_COMPLETE_LIST_CONTAINER = 'input-el--autocomplete--list--container',
  AUTO_COMPLETE_SELECTED = 'selected', // standard highlighted class
  AUTO_COMPLETE_INPUT_WRAPPER = 'input-el--autocomplete--input-wrapper',
  AUTO_COMPLETE_ICON_WRAPPER = 'input-el--autocomplete--icon-wrapper',
  AUTO_COMPLETE_WRAPPER = 'brenger-autocomplete',
  AUTO_COMPLETE_WRAPPER_WIDGET = 'brenger-autocomplete-widget',
  AUTO_COMPLETE_ZERO_RESULT = 'input-el--autocomplete--zero-result',
  HAS_LIST = 'input-el--has-list',
  HAS_VALUE = 'input-el--has-value',
  HAS_FOCUS = 'input-el--has-focus',
  HAS_FEEDBACK = 'input-el--has-feedback',
  SHOW_ZERO_RESULT = 'input-el--show-zero',
  LABEL = 'input-el--label',
  FEEDBACK = 'input-el--feedback',
  FEEDBACK_SHOW = 'input-el--feedback-show',
  CLEAR = 'input-el--autocomplete--clear',
  WARNING_ICON = 'input-el--warning-icon',
}

// Interfaces
export interface InputOptions {
  name: string;
  label: string;
  error: string;
  error_no_value: string;
  placeHolder?: string;
}

export interface AutoCompleteOptions {
  text: string;
  inputParent: HTMLElement | null;
  callback: (e) => void;
  name: string;
}

export interface NavItem {
  title: string;
  url: string;
  image?: string;
  extra_classes: string;
  extra_link_classes?: string;
  children?: NavItem[];
}

export enum Cookie {
  UTM_LANDING = 'utm_landing',
}
