"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.IconEditBox = void 0;
var React = __importStar(require("react"));
var IconEditBox = function (props) { return (React.createElement("svg", __assign({ width: "1em", height: "1em", viewBox: "0 0 16 16", fill: "currentColor", xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M13.1429 7.99999C12.8273 7.99999 12.5714 8.25582 12.5714 8.57143V14.2857C12.5714 14.6013 12.3156 14.8572 12 14.8572H1.71429C1.39869 14.8572 1.14285 14.6013 1.14285 14.2857V2.85711C1.14285 2.54151 1.39869 2.28567 1.71429 2.28567H8.57146C8.88706 2.28567 9.1429 2.02983 9.1429 1.71423C9.1429 1.39863 8.88706 1.14282 8.57146 1.14282H1.71429C0.767514 1.14282 0 1.91034 0 2.85711V14.2857C0 15.2325 0.767514 16 1.71429 16H12C12.9468 16 13.7143 15.2325 13.7143 14.2857V8.57139C13.7143 8.25582 13.4585 7.99999 13.1429 7.99999Z" }),
    React.createElement("path", { d: "M15.36 0.640792C14.9498 0.230434 14.3932 -5.79239e-05 13.813 9.04058e-06C13.2323 -0.00166507 12.6753 0.229228 12.2661 0.64116L4.7392 8.16743C4.67676 8.23034 4.62965 8.30682 4.60149 8.39086L3.45864 11.8194C3.3589 12.1189 3.52079 12.4424 3.82022 12.5422C3.87831 12.5615 3.93915 12.5714 4.00035 12.5714C4.06169 12.5713 4.12266 12.5615 4.18092 12.5423L7.60951 11.3995C7.69371 11.3713 7.77022 11.324 7.83293 11.2612L15.3598 3.73432C16.2141 2.88012 16.2142 1.49509 15.36 0.640792ZM14.5518 2.92686L7.12321 10.3555L4.90377 11.0966L5.64262 8.88L13.0741 1.45143C13.4826 1.04368 14.1444 1.04435 14.5522 1.4529C14.7469 1.64807 14.8567 1.91228 14.8575 2.18801C14.8582 2.46521 14.7482 2.73119 14.5518 2.92686Z" }))); };
exports.IconEditBox = IconEditBox;
