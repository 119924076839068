"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.IconHeavy = void 0;
var React = __importStar(require("react"));
var IconHeavy = function (props) {
    return (React.createElement("svg", __assign({ xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", height: "1em", width: "1em", viewBox: "0 0 80.4 84" }, props),
        React.createElement("g", null,
            React.createElement("path", { d: "M80,68.8L73.6,45c-0.3-1.1-1.4-1.7-2.5-1.4c-1.1,0.3-1.7,1.4-1.4,2.5l6.4,23.8c0.7,2.4,0.1,5-1.4,7S70.9,80,68.4,80H12.1\n\t\tc-2.5,0-4.9-1.2-6.4-3.2c-1.5-2-2-4.6-1.4-7L10.7,46c0.3-1.1-0.3-2.2-1.4-2.5c-1.1-0.3-2.2,0.3-2.5,1.4L0.4,68.8\n\t\tc-1,3.7-0.2,7.5,2.1,10.5S8.3,84,12.1,84h56.3c3.8,0,7.3-1.7,9.6-4.7C80.2,76.3,81,72.5,80,68.8z" }),
            React.createElement("path", { d: "M10.9,37.4c1.1,0.3,2.2-0.3,2.5-1.4l2.5-9.4c0.9-3.5,4.2-6,7.8-6h33.1c3.6,0,6.9,2.5,7.8,6l2.5,9.4c0.2,0.9,1,1.5,1.9,1.5\n\t\tc0.2,0,0.3,0,0.5-0.1c1.1-0.3,1.7-1.4,1.4-2.5l-2.5-9.4c-0.8-2.8-2.5-5.1-4.7-6.7l1.4-4.2c0.9-2.7,0.6-5.6-0.8-8\n\t\tc-1.4-2.4-3.8-4.2-6.5-4.8C52.4,0.6,46.5,0,40.3,0c0,0,0,0-0.1,0c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0c0,0,0,0-0.1,0\n\t\tc-6.2,0-12.1,0.6-17.5,1.8c-2.7,0.6-5.1,2.3-6.5,4.8c-1.4,2.4-1.7,5.4-0.8,8l1.5,4.5c-2.1,1.6-3.7,3.8-4.4,6.5L9.5,35\n\t\tC9.2,36,9.8,37.1,10.9,37.4z M18.7,13.3c-0.5-1.6-0.4-3.3,0.5-4.8c0.8-1.4,2.2-2.5,3.9-2.8C28.3,4.6,34,4,40,4c6,0,11.7,0.6,17,1.7\n\t\tc1.6,0.4,3,1.4,3.9,2.8c0.8,1.5,1,3.2,0.5,4.8l-1.3,3.8c-1.1-0.3-2.2-0.5-3.3-0.5H23.7c-1.3,0-2.5,0.2-3.7,0.6L18.7,13.3z" }),
            React.createElement("path", { d: "M26.4,34.8h-0.5c-0.9,0-1.5,0.7-1.5,1.5v24.3c0,0.9,0.7,1.5,1.5,1.5h0.5c0.9,0,1.5-0.7,1.5-1.5v-6.1l3.4-3.4l4.4,10.1\n\t\tc0.2,0.6,0.8,1,1.5,1h0.5c0.9,0,1.5-0.6,1.5-1.4c0-0.3-0.1-0.5-0.2-0.7L34,48.4l4-4.1c0.6-0.6,0.5-1.2,0.3-1.7\n\t\tc-0.3-0.5-0.8-0.8-1.3-0.8h-0.7c-0.5,0-0.9,0.2-1.2,0.5l-7.1,7.2V36.3C27.9,35.4,27.3,34.8,26.4,34.8z" }),
            React.createElement("path", { d: "M46.3,65.7c-0.9,0-1.5,0.7-1.5,1.4l0,0.6c0,0.9,0.7,1.5,1.5,1.5c0,0,0,0,0,0c0.2,0,0.4,0,0.6,0c2.9,0,5.1-0.7,6.6-2\n\t\tc1.7-1.5,2.6-3.6,2.6-6.5V43.4c0-0.9-0.7-1.5-1.5-1.5H54c-0.7,0-1.2,0.4-1.4,0.9c-0.2-0.1-0.3-0.2-0.5-0.3\n\t\tc-1.1-0.6-2.3-0.9-3.7-0.9c-1.8,0-3.4,0.5-4.8,1.6c-1.3,1-2.2,2.4-2.6,4c-0.3,1.1-0.4,2.7-0.4,4.9c0,2.1,0.1,3.7,0.4,4.7\n\t\tc0.4,1.7,1.3,3.1,2.6,4.1c1.3,1,2.9,1.5,4.8,1.5c1.4,0,2.7-0.3,3.7-0.9c0.1-0.1,0.2-0.1,0.3-0.2c-0.1,1.5-0.5,2.6-1.4,3.3\n\t\tC50.2,65.4,48.5,65.7,46.3,65.7z M52.4,55.8c-0.1,0.9-0.5,1.6-1.2,2.3c-0.7,0.6-1.5,0.9-2.6,0.9c-1.1,0-2-0.3-2.7-0.9\n\t\tc-0.7-0.6-1.2-1.4-1.4-2.4c-0.1-0.4-0.3-1.4-0.3-3.7c0-1.7,0.1-2.9,0.3-3.6c0.2-1,0.7-1.7,1.5-2.4c0.7-0.6,1.6-0.9,2.6-0.9\n\t\tc1.1,0,1.9,0.3,2.6,0.9c0.7,0.6,1.1,1.3,1.2,2.2c0,0.4,0.1,0.8,0.1,1.3c0,0.5,0,1.4,0,2.5s0,2,0,2.5C52.4,55,52.4,55.5,52.4,55.8z" }))));
};
exports.IconHeavy = IconHeavy;
