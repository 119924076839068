import { getActiveLanguage } from '../helpers/translations';

export const brengerLogoAndLink = (): string => {
  const existingLogoCol = document.querySelector('.hugo-menu .logo-col');
  if (existingLogoCol) {
    return existingLogoCol.innerHTML;
  }
  // else return default brenger logo
  return `
  <a href="/${getActiveLanguage()}/"><img class="brenger-logo" alt="Brenger" src="https://www.brenger.nl/img/logo.svg"></a>
        `;
};
