import { Config } from '../config';
import { getActiveLanguage } from './helpers/translations';

export const missingImageSrc = 'https://www.brenger.nl/img/placeholder.jpg?nf_resize=fit&w=100';

export const validUriComponentPattern = new RegExp('[;/?:@&=+$,#]');

export const getCustomerBaseUrl = (): string => {
  if (typeof BRENGER_TR_FLOW !== 'undefined') {
    return BRENGER_TR_FLOW;
  }
  return `${window.location.origin}/${getActiveLanguage()}/customer`;
};

export const mainBaseUrl = `${Config.BRENGER_BASE_URL}/${getActiveLanguage()}`;

export const widgetAddressWhiteList = [
  'administrative_area',
  'country_code',
  'country_name',
  'latitude',
  'line1',
  'line2',
  'locality',
  'longitude',
  'municipality',
  'postal_code',
  'secondary_subdivision',
];
