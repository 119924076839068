import { GeoAutocomplete, GeoLocationDetails } from '@brenger/api-client';
import { showSelectedItem } from '../widget/autocompleteInput';
import { fireFullResetEvent, resetDatesOnly } from '../widget/resetLogic';
import { isValidAddress } from './isValidAddress';

export const storeItem = (key: string, value: GeoLocationDetails): void => {
  if (!value || !key) {
    return;
  }
  localStorage.setItem(
    key,
    JSON.stringify({
      ...value,
      date: Date.now(),
    })
  );
};
const autocompleteStoragePrefix = 'ac_results_';

export const storeAutocompleteResults = (key: string, results: GeoAutocomplete[]): void => {
  localStorage.setItem(autocompleteStoragePrefix + key, JSON.stringify(results));
};

export const getStoredAutocompleteResults = (key: string): GeoAutocomplete[] => {
  const storage = localStorage.getItem(autocompleteStoragePrefix + key);
  if (storage === null) {
    return [];
  }
  return JSON.parse(storage);
};

export const reHydrate = (element: HTMLInputElement): void => {
  const name = element.getAttribute('name');
  if (!name) {
    return;
  }
  const storage = localStorage.getItem(name);
  if (storage === null) {
    // there is no storage
    fireFullResetEvent(name);
    return;
  }

  const parsedStorage = JSON.parse(storage);
  if (!parsedStorage.date) {
    // storage is not timestamped
    fireFullResetEvent(name);
    return;
  }

  if (!parsedStorage.address || !isValidAddress(parsedStorage.address)) {
    // storage has no valid address
    fireFullResetEvent(name);
    return;
  }
  const diffInHours = Math.floor((Date.now() - parsedStorage.date) / 1000 / 60 / 60);
  if (diffInHours >= 24) {
    // storage is too old
    fireFullResetEvent(name);
    return;
  }

  const timeStampHours = new Date(parsedStorage.date).getHours();
  const nowHours = new Date().getHours();
  if (nowHours > 12 && timeStampHours <= 12) {
    // storage is from before 12 and it is now after twelve,
    // transport date prices are different, so we have to flag a reset for that.
    resetDatesOnly();
  }

  // Rehydrate saved address data.
  showSelectedItem(name, parsedStorage);
};
