import { GeoAutocomplete } from '@brenger/api-client';
import { Config } from '../../config';

const pushToDataLayer = (event): void => {
  if (Config.NODE_ENV === 'development') {
    if (Config.LOG) {
      // eslint-disable-next-line no-console
      console.log(event);
    }
    return;
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  window.dataLayer = window.dataLayer || [];
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  window.dataLayer.push(event);
};

export enum TrackingStorageKeys {
  ZERO_RESULTS = 'tr_zero_result_input',
  FIRST_INTERACTION_TIMESTAMP = 'tr_first_interaction_timestamp',
  DOM_READY_TIMESTAMP = 'tr_dom_ready_timestamp',
}

type EventCategory = 'Widget interaction';
type EventAction =
  | 'Select'
  | 'Zero results'
  | 'First interaction'
  | 'First interaction > to complete'
  | 'Widget loaded > to complete';

interface IEventData {
  eventCategory: EventCategory;
  eventAction: EventAction;
  eventLabel?: string;
  eventValue?: number;
}

export const trackEvent = (eventData: IEventData): void => {
  pushToDataLayer({
    event: 'trackEvent',
    ...eventData,
  });
};

export const trackZeroResultsInput = (inputText?: string): void => {
  /* We get either the value that we need to track, or we need to get the value from local storage */
  const trackedInput = inputText || sessionStorage.getItem(TrackingStorageKeys.ZERO_RESULTS);
  if (!trackedInput) {
    return;
  }
  /* Clear stored value */
  sessionStorage.removeItem(TrackingStorageKeys.ZERO_RESULTS);
  trackEvent({
    eventCategory: 'Widget interaction',
    eventAction: 'Zero results',
    eventLabel: trackedInput,
  });
};

export const trackZeroResults = (newInput: string, response: GeoAutocomplete[]): void => {
  /* This zero results thing is about result count and length of input */
  if (response.length !== 0 || newInput.length < 5) {
    /* If we end up more then 0 results, we would like to track the last failed attempt if there is any */
    trackZeroResultsInput();
    return;
  }
  const storedValue = sessionStorage.getItem(TrackingStorageKeys.ZERO_RESULTS);
  /* We store the input value to local storage when:
   * - we don't have any stored value
   * - when we have a stored value, and it's part of the newInput (so the user is typing)
   * - when we have a stored value, and the newInput is part of the storedValue (so the user is backspacing)
   */
  if (!storedValue || newInput.includes(storedValue) || storedValue.includes(newInput)) {
    sessionStorage.setItem(TrackingStorageKeys.ZERO_RESULTS, newInput);
    return;
  }
  /* In this case newInput is not related to the stored value, so we are interested */
  trackZeroResultsInput(newInput);
};

export const getTimeInRoundedSeconds = (roundToSecs = 5): number => {
  const secs = 1000 * roundToSecs;
  return Math.round(new Date().getTime() / secs) * roundToSecs;
};

export const trackTimeRelatedEvents = (): void => {
  const timeRelatedStorageKeys = [
    TrackingStorageKeys.FIRST_INTERACTION_TIMESTAMP,
    TrackingStorageKeys.DOM_READY_TIMESTAMP,
  ];
  let i = 0;
  do {
    const storageKey = timeRelatedStorageKeys[i];
    const startTime = sessionStorage.getItem(storageKey);
    if (!startTime) {
      return;
    }
    /* We have something in store, let's track */
    const now = getTimeInRoundedSeconds();
    // get the diff in seconds, in steps of 5
    const diffInStepsOfFiveSeconds = now - parseInt(startTime); //
    trackEvent({
      eventCategory: 'Widget interaction',
      eventAction:
        storageKey === TrackingStorageKeys.FIRST_INTERACTION_TIMESTAMP
          ? 'First interaction > to complete'
          : 'Widget loaded > to complete',
      eventLabel: String(diffInStepsOfFiveSeconds),
    });
    sessionStorage.removeItem(storageKey);
    i++;
  } while (timeRelatedStorageKeys.length >= i);
};

export const cleanUpTracking = (): void => {
  sessionStorage.removeItem(TrackingStorageKeys.FIRST_INTERACTION_TIMESTAMP);
};
