"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.IconChat = void 0;
var React = __importStar(require("react"));
var IconChat = function (props) {
    return (React.createElement("svg", __assign({ width: '1em', height: '1em', viewBox: "0 0 25 24", fill: "currentColor", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("g", null,
            React.createElement("path", { d: "M4.18437 17.5331C4.34656 17.6972 4.47083 17.8953 4.54835 18.1134C4.62586 18.3314 4.6547 18.564 4.63281 18.7946C4.523 19.8618 4.31481 20.9164 4.01094 21.9446C6.19062 21.4359 7.52188 20.8469 8.12656 20.5382C8.46954 20.3631 8.86451 20.3216 9.23594 20.4216C10.3007 20.7077 11.3981 20.8518 12.5 20.85C18.7437 20.85 23.4375 16.429 23.4375 11.4C23.4375 6.3726 18.7437 1.95 12.5 1.95C6.25625 1.95 1.5625 6.3726 1.5625 11.4C1.5625 13.7121 2.52656 15.8573 4.18437 17.5331ZM3.41406 23.6834C3.04386 23.7574 2.67245 23.8251 2.3 23.8866C1.9875 23.937 1.75 23.6094 1.87344 23.3165C2.01217 22.9866 2.13934 22.652 2.25469 22.3132L2.25937 22.2974C2.64687 21.1634 2.9625 19.8593 3.07812 18.645C1.16094 16.7078 0 14.172 0 11.4C0 5.31105 5.59687 0.375 12.5 0.375C19.4031 0.375 25 5.31105 25 11.4C25 17.489 19.4031 22.425 12.5 22.425C11.262 22.4267 10.029 22.2647 8.83281 21.9431C8.02031 22.3573 6.27187 23.1117 3.41406 23.6834Z" }),
            React.createElement("path", { d: "M6.25 7.4623C6.25 7.25345 6.33231 7.05314 6.47882 6.90546C6.62534 6.75777 6.82405 6.6748 7.03125 6.6748H17.9688C18.176 6.6748 18.3747 6.75777 18.5212 6.90546C18.6677 7.05314 18.75 7.25345 18.75 7.4623C18.75 7.67116 18.6677 7.87147 18.5212 8.01915C18.3747 8.16684 18.176 8.2498 17.9688 8.2498H7.03125C6.82405 8.2498 6.62534 8.16684 6.47882 8.01915C6.33231 7.87147 6.25 7.67116 6.25 7.4623ZM6.25 11.3998C6.25 11.1909 6.33231 10.9906 6.47882 10.843C6.62534 10.6953 6.82405 10.6123 7.03125 10.6123H17.9688C18.176 10.6123 18.3747 10.6953 18.5212 10.843C18.6677 10.9906 18.75 11.1909 18.75 11.3998C18.75 11.6087 18.6677 11.809 18.5212 11.9567C18.3747 12.1043 18.176 12.1873 17.9688 12.1873H7.03125C6.82405 12.1873 6.62534 12.1043 6.47882 11.9567C6.33231 11.809 6.25 11.6087 6.25 11.3998ZM6.25 15.3373C6.25 15.1284 6.33231 14.9281 6.47882 14.7805C6.62534 14.6328 6.82405 14.5498 7.03125 14.5498H13.2812C13.4885 14.5498 13.6872 14.6328 13.8337 14.7805C13.9802 14.9281 14.0625 15.1284 14.0625 15.3373C14.0625 15.5462 13.9802 15.7465 13.8337 15.8942C13.6872 16.0418 13.4885 16.1248 13.2812 16.1248H7.03125C6.82405 16.1248 6.62534 16.0418 6.47882 15.8942C6.33231 15.7465 6.25 15.5462 6.25 15.3373Z" }))));
};
exports.IconChat = IconChat;
