import { autocompleteStickyCTA } from '../render/autocompleteStickyCTA';
import { FormClasses, CustomEvents } from '../interface';

export const initAutocompleteStickyCTA = (): void => {
  const form = document.querySelector(FormClasses.MAIN);
  if (!form) {
    return;
  }

  // create new div
  const div = document.createElement('div');
  div.classList.add('brenger-tr-widget--wrapper', 'sticky-cta--wrapper');
  // add CTA button
  div.innerHTML = autocompleteStickyCTA();

  // listen to clicks and scroll to form with focussed input
  div.addEventListener('click', () => {
    document.dispatchEvent(new CustomEvent(CustomEvents.WIDGET_FOCUS));
  });

  document.body.append(div);

  // listen to scroll event to determine if we need to show the CTA
  window.addEventListener('scroll', () => {
    if (!isInViewport(form, 100)) {
      document.body.classList.add('show-sticky-cta');
    } else {
      document.body.classList.remove('show-sticky-cta');
    }
  });
};

const isInViewport = (element, offSetY = 0): boolean => {
  const rect = element.getBoundingClientRect();
  return (
    rect.top >= 0 - offSetY &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) - offSetY &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
};
