"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.IconPhoneClassic = void 0;
var React = __importStar(require("react"));
var IconPhoneClassic = function (props) { return (React.createElement("svg", __assign({ width: '1em', height: '1em', viewBox: "0 0 17 17", fill: "currentColor", xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M13.1761 16.9989C12.043 16.9757 11.1064 16.681 10.193 16.2974C8.53786 15.6013 7.07221 14.6067 5.71716 13.4403C4.39888 12.3131 3.23259 11.0194 2.24755 9.59173C1.31943 8.24597 0.561469 6.81821 0.177074 5.21644C-0.00700275 4.44688 -0.0959472 3.6719 0.154644 2.90543C0.237814 2.65638 0.366299 2.42484 0.533625 2.22249C1.02243 1.63236 1.54682 1.0693 2.04336 0.488458C2.35119 0.131134 2.70696 -0.0761457 3.195 0.0282674C3.50437 0.0947824 3.7364 0.282726 3.95683 0.498513C4.39923 0.9404 4.84034 1.38306 5.28017 1.82649C5.70246 2.25188 6.12475 2.67727 6.54395 3.10497C7.00801 3.57831 7.02348 4.19319 6.57257 4.67813C6.25237 5.02231 5.90665 5.34251 5.58335 5.68359C5.44923 5.82235 5.33426 5.97842 5.2415 6.14765C5.17432 6.28721 5.15252 6.4443 5.17915 6.59688C5.20578 6.74946 5.27951 6.88987 5.38999 6.99842C6.92964 8.53909 8.46979 10.0793 10.0105 11.6189C10.2185 11.8262 10.5697 11.9074 10.8303 11.7736C11.0061 11.6808 11.1683 11.5642 11.3122 11.4271C11.6409 11.1177 11.9487 10.7882 12.2751 10.4773C12.8041 9.97226 13.419 9.97613 13.9364 10.4874C14.7887 11.3304 15.6377 12.1763 16.4833 13.025C16.7246 13.2678 16.9474 13.5269 16.9891 13.8912C17.0309 14.2401 16.9528 14.5486 16.6883 14.7861C16.1244 15.295 15.5281 15.7753 14.9867 16.3059C14.4453 16.8365 13.7763 17.0027 13.1761 16.9989Z" }))); };
exports.IconPhoneClassic = IconPhoneClassic;
