import mergedTranslations from '../../i18n/merged.json';
import { _get } from './getLodash';
import { Config } from '../../config';

export const getActiveLanguage = (): string => {
  const langs = Config.SUPPORTED_LANGUAGES;
  const langPrefixInPath = window.location.pathname.split('/')[1];
  const activeLang = langs.find(lang => langPrefixInPath === lang);
  if (activeLang === 'nl-be') {
    return 'nl';
  }
  return langs.find(lang => langPrefixInPath === lang) || 'en-nl';
};

export const ACTIVE_LANG = getActiveLanguage();

export const EXCLUDED_FROM_LANG_SWITCHER = ['nl-be', 'en'];

export const getActiveLocale = (): string => {
  return (
    {
      nl: 'nl_NL',
      'en-nl': 'en_NL',
      'de-de': 'de_DE',
      'fr-fr': 'fr_FR',
      'es-es': 'es_ES',
    }[ACTIVE_LANG] || 'nl_NL'
  );
};

export const translate = (key: string): string => {
  return _get(mergedTranslations, ACTIVE_LANG + '.' + key, `(!)Key not found: ${key} for lang: ${ACTIVE_LANG}`);
};
