"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.IconPencil = void 0;
var React = __importStar(require("react"));
var IconPencil = function (props) { return (React.createElement("svg", __assign({ width: "1em", height: "1em", viewBox: "0 0 14 14", fill: "currentColor", xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M0.875 10.7158V12.7847C0.875 12.9753 1.02472 13.125 1.21528 13.125H3.28417C3.37264 13.125 3.46111 13.091 3.52236 13.0229L10.954 5.59805L8.40194 3.04596L0.977083 10.4708C0.909028 10.5389 0.875 10.6205 0.875 10.7158ZM12.9276 3.62444C13.1931 3.35902 13.1931 2.93027 12.9276 2.66485L11.3351 1.07235C11.0697 0.806937 10.641 0.806937 10.3756 1.07235L9.13014 2.31777L11.6822 4.86985L12.9276 3.62444Z" }))); };
exports.IconPencil = IconPencil;
