"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.PriceClient = void 0;
var ProductSelection_1 = require("./clients/ProductSelection");
var Quote_1 = require("./clients/Quote");
var Axios_1 = require("./utils/Axios");
var PriceClient = /** @class */ (function (_super) {
    __extends(PriceClient, _super);
    function PriceClient(axiosInstance) {
        var _this = _super.call(this) || this;
        _this.quotes = new Quote_1.QuoteClient(axiosInstance);
        _this.productSelection = new ProductSelection_1.ProductSelectionClient(axiosInstance);
        return _this;
    }
    return PriceClient;
}(Axios_1.AxiosUtils));
exports.PriceClient = PriceClient;
