"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.IconLanguage = void 0;
var React = __importStar(require("react"));
var IconLanguage = function (props) { return (React.createElement("svg", __assign({ width: '2em', height: '1em', viewBox: "0 0 20 11", fill: "currentColor", xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M9.43195 2.9152H5.56034V0.587115C5.56034 0.263018 5.31031 0 5.00172 0C4.69314 0 4.4431 0.263018 4.4431 0.587115V2.9152H0.55862C0.250252 2.9152 0 3.17821 0 3.50231C0 3.82664 0.250252 4.08942 0.55862 4.08942H6.42536C5.85716 5.29381 4.3091 7.74949 0.54266 9.7923C0.311998 9.91562 0.191995 10.1892 0.252777 10.4536C0.313559 10.718 0.539098 10.9038 0.797814 10.9026C0.887095 10.9033 0.97482 10.8802 1.05319 10.8357C2.38459 10.1328 3.62474 9.25339 4.74309 8.21883L7.83918 10.8659C7.93737 10.9527 8.06183 11.0005 8.1903 11C8.42742 11.0012 8.63938 10.8451 8.71908 10.6104C8.79879 10.3757 8.72888 10.1136 8.54453 9.95682L5.5602 7.38373C6.45056 6.4337 7.16303 5.31629 7.66044 4.08944H9.44138C9.74997 4.08944 10 3.82666 10 3.50233C10 3.17823 9.74997 2.91521 9.44138 2.91521L9.43195 2.9152Z" }),
    React.createElement("path", { d: "M19.9625 10.1982L16.0739 0.32781L16.0251 0.257631C16.0251 0.257631 16.0251 0.224291 16.0008 0.210768L15.9624 0.163904L15.9345 0.123803L15.8893 0.0904624C15.8757 0.076473 15.8607 0.0641163 15.8439 0.0536246L15.7951 0.0268123L15.7359 0H15.308L15.2487 0.0268123L15.1999 0.0536246L15.1547 0.0869651L15.1096 0.123803L15.0712 0.167169V0.167402C15.0576 0.182324 15.0447 0.197945 15.0328 0.214032L15.0049 0.257397V0.25763C14.9942 0.275816 14.985 0.294935 14.9772 0.314519L11.0398 10.198C10.9196 10.5044 11.0808 10.8464 11.3999 10.9618C11.7193 11.0772 12.0753 10.9222 12.1955 10.6158L13.1251 8.25611H17.8945L18.8102 10.6158C18.9015 10.8424 19.1281 10.9921 19.3811 10.9935C19.455 10.9935 19.5283 10.9798 19.597 10.9534C19.7498 10.9 19.8743 10.7902 19.9428 10.6485C20.0115 10.5069 20.0186 10.3447 19.9625 10.198L19.9625 10.1982ZM13.5879 7.08629L15.5025 2.18639L17.4173 7.08629H13.5879Z" }))); };
exports.IconLanguage = IconLanguage;
