"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.IconStopwatchExclamation = void 0;
var React = __importStar(require("react"));
var IconStopwatchExclamation = function (props) {
    return (React.createElement("svg", __assign({ xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", width: "1em", height: "1em", viewBox: "0 0 48 52" }, props),
        React.createElement("path", { d: "M38.1867 13.4798L39.1181 12.5566C39.8011 12.9874 40.6083 12.9259 41.1671 12.3719C41.788 11.7564 41.788 10.71 41.1671 10.0945L38.8697 7.81708C38.2488 7.20156 37.1933 7.20156 36.5723 7.81708C36.0135 8.37105 35.8893 9.17122 36.3861 9.84829L35.3926 10.7716C32.288 8.30949 28.5004 6.6476 24.4024 6.15518V4.24708C25.3337 4.06242 26.1409 3.20069 26.1409 2.15431C26.1409 0.984829 25.1475 0 23.9677 0H19.6213C18.4415 0 17.4481 0.923277 17.4481 2.15431C17.4481 3.20069 18.2553 4.06242 19.1866 4.24708V6.21673C8.44477 7.50932 0.000294978 16.619 0.000294978 27.6983C-0.0617968 39.6394 9.68661 49.303 21.7324 49.303C33.7782 49.303 43.5266 39.6394 43.5266 27.6983C43.5266 22.2818 41.5397 17.2961 38.1867 13.4798ZM35.3305 41.1166C31.6671 44.7482 26.886 46.7178 21.7324 46.7178C16.5788 46.7178 11.7977 44.7482 8.13431 41.1166C4.53299 37.5466 2.48396 32.7456 2.48396 27.6368C2.48396 22.528 4.4709 17.7885 8.13431 14.1569C11.7977 10.6485 16.6409 8.67881 21.7324 8.67881C26.8239 8.67881 31.6671 10.6485 35.3305 14.28C38.9318 17.85 40.9808 22.6511 40.9808 27.7599C40.9188 32.7456 38.9318 37.5466 35.3305 41.1166Z" }),
        React.createElement("path", { d: "M7.76174 27.5756C7.76174 27.0832 7.38919 26.7139 6.89246 26.7139H5.15389C4.65716 26.7139 4.28461 27.0832 4.28461 27.5756C4.28461 28.068 4.65716 28.4373 5.15389 28.4373H6.89246C7.38919 28.4373 7.76174 28.068 7.76174 27.5756Z" }),
        React.createElement("path", { d: "M21.7323 13.7882C22.2291 13.7882 22.6637 13.4189 22.6637 12.9265V11.203C22.6637 10.7106 22.2291 10.3413 21.7323 10.3413C21.2356 10.3413 20.801 10.7106 20.801 11.203V12.9265C20.801 13.4189 21.2356 13.7882 21.7323 13.7882Z" }),
        React.createElement("path", { d: "M38.3108 26.7139H36.5722C36.0755 26.7139 35.7029 27.0832 35.7029 27.5756C35.7029 28.068 36.0755 28.4373 36.5722 28.4373H38.3108C38.8075 28.4373 39.1801 28.068 39.1801 27.5756C39.1801 27.1447 38.8075 26.7139 38.3108 26.7139Z" }),
        React.createElement("path", { d: "M21.7323 41.4854C21.2356 41.4854 20.801 41.8547 20.801 42.3471V44.0705C20.801 44.5629 21.2356 44.9322 21.7323 44.9322C22.2291 44.9322 22.6637 44.5629 22.6637 44.0705V42.3471C22.6637 41.8547 22.2291 41.4854 21.7323 41.4854Z" }),
        React.createElement("path", { d: "M8.44491 34.2842L6.95471 35.1459C6.52007 35.3921 6.39589 35.8845 6.64425 36.3154C6.89262 36.7462 7.38936 36.8693 7.824 36.6231L9.3142 35.7614C9.74884 35.5152 9.87302 35.0228 9.62466 34.5919C9.43838 34.1611 8.87956 34.038 8.44491 34.2842Z" }),
        React.createElement("path", { d: "M14.2193 12.9879C13.971 12.5571 13.4742 12.434 13.0396 12.6802C12.6049 12.9264 12.4808 13.4188 12.7291 13.8497L13.5984 15.3269C13.8468 15.7578 14.3435 15.8809 14.7782 15.6347C15.2128 15.3885 15.337 14.896 15.0886 14.4652L14.2193 12.9879Z" }),
        React.createElement("path", { d: "M35.0201 21.0514L36.5103 20.1896C36.945 19.9434 37.0692 19.451 36.8208 19.0202C36.5724 18.5893 36.0757 18.4662 35.6411 18.7124L34.1508 19.5741C33.7162 19.8203 33.592 20.3127 33.8404 20.7436C34.0888 21.1745 34.5855 21.2976 35.0201 21.0514Z" }),
        React.createElement("path", { d: "M29.9285 40.0089C29.6801 39.5781 29.1834 39.455 28.7487 39.7012C28.3141 39.9474 28.1899 40.4398 28.4383 40.8707L29.3076 42.3479C29.5559 42.7788 30.0527 42.9019 30.4873 42.6557C30.922 42.4094 31.0461 41.917 30.7978 41.4862L29.9285 40.0089Z" }),
        React.createElement("path", { d: "M14.7782 39.6396C14.3435 39.3934 13.8468 39.5165 13.5984 39.9474L12.7291 41.4246C12.4808 41.8555 12.6049 42.3479 13.0396 42.5941C13.4742 42.8403 13.971 42.7172 14.2193 42.2864L15.0886 40.8091C15.337 40.4398 15.2128 39.8859 14.7782 39.6396Z" }),
        React.createElement("path", { d: "M9.3142 19.5741L7.824 18.7124C7.38936 18.4662 6.89262 18.5893 6.64425 19.0202C6.39589 19.451 6.52007 19.9434 6.95471 20.1896L8.44491 21.0514C8.87956 21.2976 9.37629 21.1745 9.62466 20.7436C9.87302 20.3743 9.74884 19.8203 9.3142 19.5741Z" }),
        React.createElement("path", { d: "M30.4873 12.6802C30.0527 12.434 29.5559 12.5571 29.3076 12.9879L28.4383 14.4652C28.1899 14.896 28.3141 15.3885 28.7487 15.6347C29.1834 15.8809 29.6801 15.7578 29.9285 15.3269L30.7978 13.8497C31.0461 13.4803 30.8599 12.9264 30.4873 12.6802Z" }),
        React.createElement("path", { d: "M36.5103 35.1459L35.0201 34.2842C34.5855 34.038 34.0888 34.1611 33.8404 34.5919C33.592 35.0228 33.7162 35.5152 34.1508 35.7614L35.6411 36.6231C36.0757 36.8693 36.5724 36.7462 36.8208 36.3154C37.0692 35.9461 36.945 35.3921 36.5103 35.1459Z" }),
        React.createElement("path", { d: "M27.4449 17.9732C27.2586 17.8501 26.9481 17.9117 26.824 18.0963L22.105 25.4825C21.9808 25.4825 21.8566 25.421 21.6703 25.421C20.4906 25.421 19.4971 26.4058 19.4971 27.5753C19.4971 28.7448 20.4906 29.7296 21.6703 29.7296C22.8501 29.7296 23.8436 28.7448 23.8436 27.5753C23.8436 27.0829 23.6573 26.652 23.4089 26.2827L27.5691 18.4656C27.7553 18.4041 27.6932 18.0963 27.4449 17.9732Z" }),
        React.createElement("circle", { cx: "37.1199", cy: "41.1691", r: "10.1237", fill: "#F5A623" }),
        React.createElement("path", { d: "M37 35.7827V42.7392", stroke: "white", strokeWidth: "1.6", strokeLinecap: "round", strokeLinejoin: "round" }),
        React.createElement("path", { d: "M37 45.3481V46.2177", stroke: "white", strokeWidth: "1.6", strokeLinecap: "round", strokeLinejoin: "round" })));
};
exports.IconStopwatchExclamation = IconStopwatchExclamation;
