"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.IconPersonOne = void 0;
var React = __importStar(require("react"));
var IconPersonOne = function (props) { return (React.createElement("svg", __assign({ width: '.8em', height: '1em', viewBox: "0 0 17 23", fill: "currentColor", xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M1.831 21.58h14.065v-2.577c0-3.293-2.546-5.972-5.675-5.972H7.507c-3.13 0-5.676 2.68-5.676 5.972v2.578Zm14.533.987h-15a.466.466 0 0 1-.335-.147.487.487 0 0 1-.134-.346v-3.07c0-3.837 2.966-6.959 6.612-6.959h2.714c3.645 0 6.612 3.122 6.612 6.958v3.07a.495.495 0 0 1-.135.347.475.475 0 0 1-.334.147Z" }),
    React.createElement("path", { d: "M4.866 22.567a.466.466 0 0 1-.334-.147.488.488 0 0 1-.134-.346v-4.767a.497.497 0 0 1 .134-.346.476.476 0 0 1 .334-.147.466.466 0 0 1 .334.147.486.486 0 0 1 .134.346v4.767a.496.496 0 0 1-.134.346.476.476 0 0 1-.334.147Zm8.49 0a.466.466 0 0 1-.334-.147.488.488 0 0 1-.134-.346v-4.767a.496.496 0 0 1 .135-.346.477.477 0 0 1 .334-.147.466.466 0 0 1 .334.147.488.488 0 0 1 .134.346v4.767a.497.497 0 0 1-.134.346.477.477 0 0 1-.334.147h-.001Zm-2.719-9.925-1.002 2.11a.572.572 0 0 1-.208.24.552.552 0 0 1-.809-.24l-1.003-2.11h3.022Zm3.997-6.72a.465.465 0 0 1-.301-.117.485.485 0 0 1-.158-.287c-.458-2.616-2.628-4.515-5.158-4.515-2.531 0-4.7 1.9-5.158 4.515a.489.489 0 0 1-.192.314.465.465 0 0 1-.354.082.48.48 0 0 1-.303-.208.502.502 0 0 1-.073-.366C3.477 2.257 6.034.017 9.017.017s5.54 2.24 6.079 5.323a.504.504 0 0 1-.1.4.484.484 0 0 1-.362.182Z" }),
    React.createElement("path", { d: "M9.015 13.031c-2.982 0-5.539-2.239-6.078-5.322a.502.502 0 0 1 .073-.366.48.48 0 0 1 .303-.208.459.459 0 0 1 .353.081.476.476 0 0 1 .192.315c.458 2.615 2.627 4.514 5.158 4.514 2.531 0 4.7-1.899 5.158-4.514a.49.49 0 0 1 .192-.315.464.464 0 0 1 .353-.081c.255.05.423.306.376.574-.54 3.084-3.096 5.322-6.079 5.322" }))); };
exports.IconPersonOne = IconPersonOne;
