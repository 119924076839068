"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GeoClient = void 0;
var BaseClient_1 = require("../../BaseClient");
var GeoClient = /** @class */ (function (_super) {
    __extends(GeoClient, _super);
    function GeoClient(axiosClient) {
        var _this = _super.call(this, axiosClient) || this;
        _this.whatIsMyCountry = _this.whatIsMyCountry.bind(_this);
        _this.retrieveEtaPeriodForStop = _this.retrieveEtaPeriodForStop.bind(_this);
        _this.retrieveProximityStop = _this.retrieveProximityStop.bind(_this);
        _this.retrieveRoute = _this.retrieveRoute.bind(_this);
        _this.retrieveAutocomplete = _this.retrieveAutocomplete.bind(_this);
        _this.retrieveLocationDetails = _this.retrieveLocationDetails.bind(_this);
        _this.retrieveStructuredLocation = _this.retrieveStructuredLocation.bind(_this);
        _this.retrieveStructuredPostalCode = _this.retrieveStructuredPostalCode.bind(_this);
        _this.retrieveProposedDayRoute = _this.retrieveProposedDayRoute.bind(_this);
        _this.retrieveOpenTJScoresByUserId = _this.retrieveOpenTJScoresByUserId.bind(_this);
        return _this;
    }
    /**
     * Get the current user's country.
     */
    GeoClient.prototype.whatIsMyCountry = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.fetch({
                            method: 'GET',
                            url: "/geo/what_is_my_country"
                        })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Get the expected ETA for a stop.
     */
    GeoClient.prototype.retrieveEtaPeriodForStop = function (_a) {
        var stopId = _a.stopId;
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, this.fetch({
                            method: 'GET',
                            url: "/geo/stops/".concat(stopId, "/eta_period")
                        })];
                    case 1: return [2 /*return*/, _b.sent()];
                }
            });
        });
    };
    /**
     * Get an indication of how close the user is to the stop.
     */
    GeoClient.prototype.retrieveProximityStop = function (params) {
        return __awaiter(this, void 0, void 0, function () {
            var stopId, lat, lng;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        stopId = params.stopId, lat = params.lat, lng = params.lng;
                        return [4 /*yield*/, this.fetch({
                                method: 'POST',
                                url: "/geo/stops/".concat(stopId, "/proximity"),
                                data: { coordinates: { lat: lat, lng: lng } }
                            })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Retrieve a route between two geo points
     */
    GeoClient.prototype.retrieveRoute = function (params) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.fetch({
                            method: 'GET',
                            url: "/v2/calculate_route",
                            params: { from: "".concat(params.pickup.lat, ",").concat(params.pickup.lng), to: "".concat(params.delivery.lat, ",").concat(params.delivery.lng) }
                        })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * V2 Autocomplete location query.
     * Does a cheap look up first and returns minimum presentational details only
     * NOTE: sessionToken is used for cost optimization. When supplying a token to identify user sessions,
     * Google Maps offers a nice discount on the series of autocompletes + place id lookups.
     */
    GeoClient.prototype.retrieveAutocomplete = function (args) {
        return __awaiter(this, void 0, void 0, function () {
            var params;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        params = {
                            method: 'GET',
                            url: "/v2/autocomplete",
                            params: {
                                session_token: args.sessionToken,
                                q: args.query
                            },
                            cancelToken: args.cancelToken
                        };
                        // x-user-locale header
                        // To prevent resetting the header to undefined, only apply the header if explicitly set.
                        if (args.locale && params.headers) {
                            params.headers[BaseClient_1.USER_LOCALE_HEADER] = args.locale;
                        }
                        return [4 /*yield*/, this.fetch(params)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * V2 Autocomplete retrieve location details.
     * Returns extended details on a location, looked up by previous method.
     * NOTE: sessionToken is used for cost optimization. When supplying a token to identify user sessions,
     * Google Maps offers a nice discount on the series of autocompletes + place id lookups.
     * includeHouseNumber tells geo to include the housenumber on line (if present)
     */
    GeoClient.prototype.retrieveLocationDetails = function (args) {
        return __awaiter(this, void 0, void 0, function () {
            var params;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        params = {
                            method: 'GET',
                            url: "/v2/autocomplete_select",
                            params: {
                                session_token: args.sessionToken,
                                place_id: args.placeId,
                                include_house_number: args.includeHouseNumber
                            }
                        };
                        // x-user-locale header
                        // To prevent resetting the header to undefined, only apply the header if explicitly set.
                        if (args.locale && params.headers) {
                            params.headers[BaseClient_1.USER_LOCALE_HEADER] = args.locale;
                        }
                        return [4 /*yield*/, this.fetch(params)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Structured location lookup
     * Means every address component as separate query string
     */
    GeoClient.prototype.retrieveStructuredLocation = function (params) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.fetch({
                            method: 'GET',
                            url: "/v2/geocode_structured",
                            params: params
                        })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Structured postal code lookup
     * Dutch style lookup, means country code, postal code as separate query string
     */
    GeoClient.prototype.retrieveStructuredPostalCode = function (params) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.fetch({
                            method: 'GET',
                            url: "/v2/postal_code",
                            params: params
                        })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Retrieve a proposed day route
     */
    GeoClient.prototype.retrieveProposedDayRoute = function (params) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.fetch({
                            method: 'GET',
                            url: "/day_routes/".concat(params.day_route_id, "/optimize_order"),
                            params: { include_tj: params.include_tj }
                        })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Retrieve a open TJ scores for given user
     */
    GeoClient.prototype.retrieveOpenTJScoresByUserId = function (_a) {
        var userId = _a.userId;
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, this.fetch({
                            method: 'GET',
                            url: "/open_transport_jobs/scores/".concat(userId)
                        })];
                    case 1: return [2 /*return*/, _b.sent()];
                }
            });
        });
    };
    return GeoClient;
}(BaseClient_1.BaseClient));
exports.GeoClient = GeoClient;
