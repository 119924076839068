"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseErrorMessageFromCore = void 0;
var parseErrorMessageFromCore = function (error) {
    var _a, _b, _c;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    var typedError = error;
    // First step: look for error data
    var errorData = (_a = typedError === null || typedError === void 0 ? void 0 : typedError.response) === null || _a === void 0 ? void 0 : _a.data;
    if (errorData) {
        // With error data, first check for violation keys.
        if (Array.isArray(errorData.violations) && errorData.violations.length > 0) {
            // Grab the first violation form the list
            var violation = errorData.violations[0];
            // Return the violation message if it exists
            if (violation === null || violation === void 0 ? void 0 : violation.message)
                return violation.message;
        }
        // If no violation keys, fallback to "hydra:description"
        // This is usually the combination of the violation "message: propertyPath"
        var hydraDescription = errorData['hydra:description'];
        if (hydraDescription)
            return hydraDescription;
    }
    // Second step: if error data does not have core properties above, return generic message
    if (typedError === null || typedError === void 0 ? void 0 : typedError.message)
        return typedError.message;
    // Third step: if no message, just return the status text
    if ((_b = typedError === null || typedError === void 0 ? void 0 : typedError.response) === null || _b === void 0 ? void 0 : _b.statusText)
        return (_c = typedError.response) === null || _c === void 0 ? void 0 : _c.statusText;
    // Fallback - return default error text.
    return 'Oops, something went wrong. Please try again.';
};
exports.parseErrorMessageFromCore = parseErrorMessageFromCore;
