import { PopularItem } from '@brenger/api-client';
import { FormNames } from './types';

export enum StopType {
  PICKUP = 'pickup',
  DELIVERY = 'delivery',
}

export enum GenericInputFileMethods {
  ADD = 'add',
  DELETE = 'delete',
  CHANGE = 'change',
}

export enum GenericExtraInputFileFields {
  COLLECTION = 'collection',
  COLLECTION_DUP = 'collection_dup',
  UPLOADING = 'uploading',
  UPLOAD_FEEDBACK = 'upload_feedback',
}

export enum Currencies {
  EUR = 'EUR',
}

export enum Countries {
  NL = 'NL',
}

export enum SessionStorageKeys {
  IS_TRANSACTION_EVENT_FIRED = 'IS_TRANSACTION_EVENT_FIRED',
  LANDING_PAGE_ITEM_ACTION = 'LANDING_PAGE_ITEM_ACTION',
  PRODUCT_SELECTION_FORM = 'PRODUCT_SELECTION_FORM',
  CUSTOMER_TYPE = 'GF_CUSTOMER_TYPE',
  FLOOR_SUS_PICKUP = 'FLOOR_SUS_PICKUP',
  FLOOR_SUS_DELIVERY = 'FLOOR_SUS_DELIVERY',
  INSTRUCTION_SUS_PICKUP = 'INSTRUCTION_SUS_PICKUP',
  INSTRUCTION_SUS_DELIVERY = 'INSTRUCTION_SUS_DELIVERY',
}

export enum LocalStorageKeys {
  PAYMENT_EVENT_IDS = 'payment_event_ids',
  PAYMENT_ERROR_EVENT_IDS = 'payment_error_event_ids',
  LANGUAGE_PREFERENCE = 'language_preference',
}

export enum NotGuaranteedReason {
  DISTANCE = 'distance',
  HEAVY = 'heavy',
}

/**
 * DEPRECATED: We should use the typed version from API-client
 */
export enum CarryingHelpChoices {
  NOT_NEEDED = 'not_needed',
  NEEDED = 'needed', // is default choice, as in: no extra help, the driver on its own is fine
  EXTRA_DRIVER = 'extra_driver',
  EQUIPMENT_TAILGATE = 'equipment_tailgate',
  EQUIPMENT_TAILGATE_PALLET_JACK = 'equipment_tailgate_pallet_jack',
  EQUIPMENT_TAILGATE_EXTRA_DRIVER = 'equipment_tailgate_extra_driver',
  EQUIPMENT_PROVIDED_BY_CUSTOMER = 'equipment_provided_by_customer',
}

export enum DateTimeFormat {
  DATE = 'DD-MM-YYYY',
  DATE_DE = 'DD.MM.YYYY',
  DATE_EN = 'DD-MM-YYYY',
  PRICING = 'YYYY-MM-DD',
  DATE_MONTH_LONG = 'DD MMMM',
  TIME_24H_MIN = 'HH:mm',
  DATE_TIME = 'DD-MM-YY HH:mm',
  DATE_TIME_EN = 'MM-DD-YYYY HH:mm',
}

export enum BaseActionTypes {
  CLEAN_LOCAL_STORAGE = `@@base/CLEAN_LOCAL_STORAGE`,
  START_APP_LOADING = `@@base/START_APP_LOADING`,
  STOP_APP_LOADING = `@@base/STOP_APP_LOADING`,
  REMOVE_UPLOADED_FILE = `@@base/REMOVE_UPLOADED_FILE`,
  SELECT_PRODUCT_DIMENSION = `@@base/SELECT_PRODUCT_DIMENSION`,
}

export interface GeneralBaseAction {
  type: BaseActionTypes.CLEAN_LOCAL_STORAGE | BaseActionTypes.START_APP_LOADING | BaseActionTypes.STOP_APP_LOADING;
}

export interface RemoveFileUploadPayload {
  formName: FormNames;
  fieldName: string;
  index: number;
  input_file_method: GenericInputFileMethods.DELETE;
}
export interface RemoveUploadedFileAction {
  type: BaseActionTypes.REMOVE_UPLOADED_FILE;
  payload: RemoveFileUploadPayload;
}

export interface SelectProductDimensionAction {
  type: BaseActionTypes.SELECT_PRODUCT_DIMENSION;
  payload: PopularItem | null;
  meta: {
    form: FormNames;
    field: string;
    value: string;
  };
}

export enum CacheKey {
  RETRIEVE_PS_SEARCH = 'RETRIEVE_PS_SEARCH',
  RETRIEVE_TRANSPORT_REQUEST = 'RETRIEVE_TRANSPORT_REQUEST',
  RETRIEVE_TRANSPORT_REQUEST_LIST = 'RETRIEVE_TRANSPORT_REQUEST_LIST',
  RETRIEVE_TRANSPORT_REQUEST_LIVE_TRACKING = 'RETRIEVE_TRANSPORT_REQUEST_LIVE_TRACKING',
  RETRIEVE_TRANSPORT_REQUEST_STOP_CONTACT = 'RETRIEVE_TRANSPORT_REQUEST_STOP_CONTACT',
  RETRIEVE_TRANSPORT_REQUEST_CANCELLATION_COSTS = 'RETRIEVE_TRANSPORT_REQUEST_CANCELLATION_COSTS',
  RETRIEVE_TRANSPORT_JOB_ACCOUNT_LINK = 'RETRIEVE_TRANSPORT_JOB_ACCOUNT_LINK',
  RETRIEVE_TRANSPORT_JOB_ACCOUNT_LINK_LIST_FOR_TR = 'RETRIEVE_TRANSPORT_JOB_ACCOUNT_LINK_LIST_FOR_TR',
  RETRIEVE_TRANSPORT_JOB_ACCOUNT_LINK_MESSAGES = 'RETRIEVE_TRANSPORT_JOB_ACCOUNT_LINK_MESSAGES',
  RETRIEVE_ITEM_SET = 'RETRIEVE_ITEM_SET',
  RETRIEVE_PRODUCT_PAYMENT = 'RETRIEVE_PRODUCT_PAYMENT',
  RETRIEVE_INVOICES = 'RETRIEVE_INVOICES',
  RETRIEVE_QUOTE = 'RETRIEVE_QUOTE',
  RETRIEVE_GEO_PLACE = 'RETRIEVE_GEO_PLACE',
  RETRIEVE_GEO_LOCATION = 'RETRIEVE_GEO_LOCATION',
  RETRIEVE_IMAGE = 'RETRIEVE_IMAGE',
  RETRIEVE_ADDRESS = 'RETRIEVE_ADDRESS',
  RETRIEVE_LINE_1 = 'RETRIEVE_LINE_1',
  RETRIEVE_POSTAL_CODE = 'RETRIEVE_POSTAL_CODE',
  RETRIEVE_PUBLIC_DATA = 'RETRIEVE_PUBLIC_DATA',
  RETRIEVE_HISTORICAL_ADDRESS_LIST = 'RETRIEVE_HISTORICAL_ADDRESS_LIST',
  RETRIEVE_PS_PRODUCT_GROUP = 'RETRIEVE_PS_PRODUCT_GROUP',
  API_KEY_LIST = 'API_KEY_LIST',
  RETRIEVE_THANK_YOU_DATA = 'RETRIEVE_THANK_YOU_DATA',
  RETRIEVE_OPP_METHODS = 'RETRIEVE_OPP_METHODS',
  VAT_NUMBER_VALIDATOR = 'VAT_NUMBER_VALIDATOR',
  RETRIEVE_STRUCTURED_ADDRESS_BY_POSTAL_CODE = 'RETRIEVE_STRUCTURED_ADDRESS_BY_POSTAL_CODE',
}

export enum QuoteRejectedReasonsGlobal {
  REVERSE_GEOCODING = 'Reverse geocoding failed.',
}

export enum QuotesErrorTitles {
  OUTSIDE_SERVICE_AREA = 'At least one stop is outside the service area',
  ROUTE_CALCULATION_NOT_POSSIBLE = 'Could not grade route',
}
