"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.IconCashReward = void 0;
var React = __importStar(require("react"));
var IconCashReward = function (props) { return (React.createElement("svg", __assign({ width: "1em", height: "1em", viewBox: "0 0 25 32", fill: "currentColor", xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("g", { clipPath: "url(#clip0_607_3718)" },
        React.createElement("path", { d: "M13.7081 7.69433C18.6159 10.0489 25.1315 16.7762 24.0315 22.9989C22.9314 29.2216 17.6851 31.9125 12.9465 31.9966C8.20791 32.0807 4.06163 30.7353 2.0308 28.0444C-2.97557e-05 25.3534 -0.761591 21.4012 0.930767 16.8603C2.62312 12.3193 10.0695 7.77842 10.0695 7.77842L13.7081 7.69433Z" }),
        React.createElement("path", { opacity: "0.3", d: "M13.708 7.69433C18.6159 10.0489 25.1315 16.7762 24.0314 22.9989C22.9314 29.2216 17.6851 31.9125 12.9465 31.9966C10.5298 32.0395 16.277 26.8587 17.0987 20.6528C17.8882 14.6907 13.708 7.69433 13.708 7.69433Z", fill: "#FEFEFE" }),
        React.createElement("path", { d: "M14.1777 1.4845e-08H10.0466C9.70889 -3.61216e-05 9.3768 0.08619 9.08224 0.250405C8.78767 0.41462 8.54052 0.651312 8.3645 0.937768C8.18847 1.22422 8.08948 1.55083 8.07702 1.88625C8.06457 2.22167 8.13906 2.55464 8.29336 2.85321C8.81207 3.85557 9.36124 4.92437 9.75048 5.69885C9.9175 6.03134 10.1763 6.3097 10.4967 6.50135C10.8171 6.69301 11.1858 6.79004 11.5596 6.7811L12.6893 6.75503C13.0371 6.74696 13.3765 6.64746 13.673 6.46667C13.9695 6.28587 14.2126 6.03023 14.3774 5.72576L15.9132 2.88684C16.0748 2.58847 16.1558 2.25352 16.1482 1.91473C16.1406 1.57595 16.0446 1.24493 15.8697 0.95402C15.6949 0.663115 15.447 0.422282 15.1504 0.255063C14.8538 0.0878427 14.5187 -4.17026e-05 14.1777 1.4845e-08V1.4845e-08Z" }),
        React.createElement("path", { opacity: "0.3", d: "M14.1779 1.4845e-08C14.1779 1.4845e-08 10.7932 6.7996 11.5598 6.7811L12.6894 6.75503C13.0372 6.74696 13.3767 6.64746 13.6732 6.46667C13.9697 6.28587 14.2128 6.03023 14.3776 5.72576L15.9134 2.88684C16.075 2.58847 16.156 2.25352 16.1484 1.91473C16.1408 1.57595 16.0448 1.24493 15.8699 0.95402C15.695 0.663115 15.4472 0.422282 15.1506 0.255063C14.854 0.0878427 14.5189 -4.17026e-05 14.1779 1.4845e-08V1.4845e-08Z", fill: "#FEFEFE" }),
        React.createElement("path", { d: "M11.534 20.6118C11.8139 20.6118 12.0355 20.3902 12.0355 20.1103C12.0355 19.8303 11.8139 19.6087 11.534 19.6087H9.98277V19.0956H11.534C11.8139 19.0956 12.0355 18.874 12.0355 18.594C12.0355 18.3141 11.8139 18.0925 11.534 18.0925H10.0994C10.3677 16.9728 11.0441 16.413 12.2338 16.413C13.0852 16.413 13.5867 16.6929 14.0533 17.2294C14.2049 17.4044 14.3682 17.4627 14.5315 17.4627C14.8814 17.4627 15.1613 17.1828 15.1613 16.8445C15.1613 16.7162 15.1263 16.5996 15.033 16.4596C14.5548 15.7598 13.6451 15.2 12.2338 15.2C10.2744 15.2 9.03804 16.1447 8.68814 18.0925H8.50152C8.2216 18.0925 8 18.3141 8 18.594C8 18.874 8.2216 19.0956 8.50152 19.0956H8.58317V19.6087H8.50152C8.2216 19.6087 8 19.8303 8 20.1103C8 20.3902 8.2216 20.6118 8.50152 20.6118H8.6998C9.07303 22.5362 10.3093 23.376 12.2338 23.376C13.4818 23.376 14.5665 22.8628 15.1263 22.1164C15.1963 22.0231 15.2429 21.9064 15.2429 21.7665C15.2429 21.4166 14.963 21.1366 14.6131 21.1366C14.4498 21.1366 14.2982 21.2066 14.1116 21.3932C13.5517 21.9647 13.0969 22.163 12.2338 22.163C11.0675 22.163 10.4026 21.7081 10.1227 20.6118H11.534Z", fill: "white" })),
    React.createElement("defs", null,
        React.createElement("clipPath", { id: "clip0_607_3718" },
            React.createElement("rect", { width: "24.381", height: "32", fill: "white" }))))); };
exports.IconCashReward = IconCashReward;
