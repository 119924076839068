import { AccountPublicData, AccountUtmParams, GeoAutocomplete, GeoLocationDetails } from '@brenger/api-client';
import { Config } from '../../config';
import { isValidUriComponent } from '../helpers/isValidUriComponent';
import { storeItem } from '../helpers/storage';
import { InputClasses, WidgetFields } from '../interface';
import { _AUTOCOMPLETE_SELECTED, showSelectedItem } from './autocompleteInput';
import { callAutocomplete, fetchPlaceDetails } from './fetchResults';
import { getFilteredAddress } from '../helpers/getFilteredAddress';

export const parseSearchParams = async (): Promise<void> => {
  const searchParams = getSearchParams();

  if (searchParams.get(`utm_source`)) {
    document.cookie = 'utm_source=' + searchParams.get(`utm_source`);
  }

  /* Determine prefill strategy
   * 1) There is an account prefill from one of our clients
   * 2) There are marktplaats params for prefilling
   * They will never coexist
   */
  const accountPickup = searchParams.get(AccountUtmParams.PICKUP);
  const accountDelivery = searchParams.get(AccountUtmParams.DELIVERY);

  if (accountPickup) {
    prefillByAccount('pickup', accountPickup);
    return;
  }
  if (accountDelivery) {
    prefillByAccount('delivery', accountDelivery);
    return;
  }
  prefillByPostalCode(searchParams);
};

export const getSearchParams = (): URLSearchParams => {
  return new URLSearchParams(document.location.search);
};

const prefillByPostalCode = (params: URLSearchParams): void => {
  const paramSuffix = '_postal_code';
  const prefilInputs = [WidgetFields.PICKUP, WidgetFields.DELIVERY];
  prefilInputs.map((inputName: string) => {
    // get param prefix
    const paramPrefix = inputName.indexOf('pickup') > -1 ? 'pickup_address' : 'delivery_address';
    // Check if value exist in params
    const searchValues: string[] = [];
    const paramValue = params.get(`${paramPrefix}${paramSuffix}`);
    if (paramValue !== null && isValidUriComponent(paramValue)) {
      searchValues.push(decodeURIComponent(paramValue));
    }
    // check if input elements exist
    const input = document.querySelector(`input[name=${inputName}]`) as HTMLInputElement;
    const inputParent = input ? (input.closest(`.${InputClasses.BASE}`) as HTMLElement) : null;
    if (searchValues.length === 0 || !input || !inputParent || searchValues.join().trim() === '') {
      return;
    }
    // call and handle autocomplete
    callAutocomplete({
      text: searchValues.join(','),
      inputParent,
      name: inputName,
      callback: async (results: GeoAutocomplete[]) => {
        if (results.length > 0) {
          showSelectedItem(inputName, results[0]);
          const address = await fetchPlaceDetails(results[0]);
          if (address) {
            storeItem(inputName, address);
          }
        }
      },
    });
  });
};

const prefillByAccount = async (type: 'pickup' | 'delivery', slug: string): Promise<void> => {
  try {
    const publicData = await fetch(`${Config.API_URL}/accounts/${slug}/public_data`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const accountData: AccountPublicData = await publicData.json();
    const inputName = type === 'pickup' ? WidgetFields.PICKUP : WidgetFields.DELIVERY;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { lat, lng, administrative_area, ...address } = accountData.account.address;
    const geoResult = {
      label: `${address.line1}, ${address.locality}`,
      label_html: `${address.line1}, ${address.locality}`,
      address: {
        ...address,
        secondary_subdivision: '',
        administrative_area: administrative_area || '',
        latitude: lat || 0,
        longitude: lng || 0,
      },
    };
    showSelectedItem(inputName, {
      label: geoResult.label,
      label_html: geoResult.label_html,
      place_id: '',
    });
    const event = new CustomEvent(_AUTOCOMPLETE_SELECTED, {
      detail: {
        addressDetails: getFilteredAddress(geoResult),
        name,
      },
    });
    document.dispatchEvent(event);
    storeItem(inputName, geoResult as GeoLocationDetails);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
  }
};
