"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.IconFragile = void 0;
var React = __importStar(require("react"));
var IconFragile = function (props) {
    return (React.createElement("svg", __assign({ xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", height: "1em", viewBox: "0 0 512 512", width: "1em" }, props),
        React.createElement("path", { d: "m390.926 13.507c-.766-7.668-7.219-13.507-14.925-13.507h-60c-3.979 0-7.794 1.581-10.606 4.394l-30 30c-4.565 4.565-5.697 11.54-2.81 17.314l24.079 48.156-31.985 21.324 4.74-9.48c2.111-4.223 2.111-9.193 0-13.416l-25.165-50.33 22.355-22.355c9.416-9.418 2.744-25.607-10.608-25.607h-120c-7.707 0-14.159 5.839-14.926 13.507l-15.074 150.745c-8.256 82.558 51.918 156.64 135 164.988v152.76h-45c-8.284 0-15 6.716-15 15s6.716 15 15 15h120c8.284 0 15-6.716 15-15s-6.716-15-15-15h-45v-152.76c83.195-8.358 143.243-82.556 135-164.988zm-134.925 286.493c-71.266 0-127.263-61.611-120.149-132.763l13.723-137.237h70.212l-4.393 4.393c-4.565 4.565-5.697 11.54-2.81 17.315l26.646 53.292-26.646 53.292c-6.859 13.718 8.989 27.687 21.737 19.189l90-60c6.295-4.196 8.479-12.423 5.096-19.189l-25.165-50.33 17.961-17.962h40.212l13.724 137.237c7.093 70.918-48.653 132.763-120.148 132.763z" })));
};
exports.IconFragile = IconFragile;
