import { brengerLogoAndLink } from './brengerLogo';
import { NavItem } from '../interface';
import { navListMarkup } from './navList';

const menuMarkup = (navItems: NavItem[]): string => {
  return `
    <div class="brenger-menu--wrapper">
      <header class="main-header">
        <div class="container-fluid">
            <div class="row ">
                <div class="logo-col">
                    ${brengerLogoAndLink()}
                </div>
                <div class="nav-list-col">
                    ${navListMarkup(navItems)}
                </div>
            </div>
        </div>
      </header>
    </div>
  `;
};

export default menuMarkup;
