"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.IconRefresh = void 0;
var React = __importStar(require("react"));
var IconRefresh = function (props) { return (React.createElement("svg", __assign({ height: "1em", width: "1em", viewBox: "0 0 12 12", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor" }, props),
    React.createElement("path", { d: "M10.2357 1.76437C9.01195 0.542092 7.27768 -0.162779 5.37073 0.0321855C2.61542 0.309635 0.348103 2.54423 0.0402891 5.29622C-0.372633 8.93306 2.44274 12 5.99387 12C8.38881 12 10.4459 10.5978 11.4069 8.58062C11.6471 8.07822 11.2868 7.50082 10.7312 7.50082C10.4534 7.50082 10.1907 7.65079 10.0705 7.89825C9.22216 9.72042 7.18759 10.8752 4.96532 10.3803C3.29862 10.0129 1.95474 8.65561 1.60188 6.99091C0.97124 4.08145 3.186 1.50192 5.99387 1.50192C7.24014 1.50192 8.35127 2.01932 9.1621 2.83667L8.02844 3.96897C7.55546 4.44138 7.8858 5.25123 8.55398 5.25123H11.2492C11.6622 5.25123 12 4.91379 12 4.50137V1.80936C12 1.14198 11.1892 0.804544 10.7162 1.27696L10.2357 1.76437Z" }))); };
exports.IconRefresh = IconRefresh;
