"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.IconMedal = void 0;
var React = __importStar(require("react"));
var IconMedal = function (props) { return (React.createElement("svg", __assign({ xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", width: "1em", height: "1em", viewBox: "0 0 36.47 43" }, props),
    React.createElement("g", null,
        React.createElement("path", { d: "M36.39,37.67,29.8,26.27A15.67,15.67,0,0,0,7.18,4.6,15.66,15.66,0,0,0,6.7,26.27L.11,37.67a.88.88,0,0,0-.07.7.75.75,0,0,0,1,.44l5.26-2.09.81,5.62a.73.73,0,0,0,.37.56.79.79,0,0,0,1.07-.3L15.2,31.09a16.27,16.27,0,0,0,3,.29,16.1,16.1,0,0,0,3-.29L27.81,42.6a.83.83,0,0,0,1.07.3.73.73,0,0,0,.37-.56l.81-5.62,5.26,2.09a.75.75,0,0,0,1-.44A.57.57,0,0,0,36.39,37.67Zm-28,2.21L7.73,35.5a.82.82,0,0,0-.89-.66.7.7,0,0,0-.22.07L2.54,36.53l5.3-9.16a15.61,15.61,0,0,0,5.85,3.28ZM18.25,29.8a14.1,14.1,0,0,1-10-24.06,13.84,13.84,0,0,1,10-4.19,14.1,14.1,0,0,1,10,24.06A13.84,13.84,0,0,1,18.25,29.8Zm11.62,5.11a.57.57,0,0,0-.22-.07.78.78,0,0,0-.88.66l-.66,4.38-5.3-9.23a15.69,15.69,0,0,0,5.85-3.28L34,36.53Z" }),
        React.createElement("path", { d: "M25.46,13a1.51,1.51,0,0,0-.66-.55,1.63,1.63,0,0,0-.85-.15H20.9l-1-3a2,2,0,0,0-.84-1A2,2,0,0,0,18.29,8a2,2,0,0,0-.81.18,1.72,1.72,0,0,0-.85,1l-1,3H12.62a1.65,1.65,0,0,0-.85.15,1.63,1.63,0,0,0-.66.55,1.64,1.64,0,0,0-.29,1.29,1.71,1.71,0,0,0,.7,1.14l2.57,1.84-1,3s0,.08,0,.08A1.77,1.77,0,0,0,13,21a1.47,1.47,0,0,0,.34.74,1.76,1.76,0,0,0,1.14.7,1.65,1.65,0,0,0,1.28-.33l2.58-1.88,2.57,1.88a1.92,1.92,0,0,0,1.29.33A1.82,1.82,0,0,0,23.69,21a1.65,1.65,0,0,0,0-.73s0-.08,0-.08l-1-3,2.57-1.84a1.71,1.71,0,0,0,.7-1.14A2.62,2.62,0,0,0,25.46,13Zm-1.29,1.18,0,0-3,2.17a.78.78,0,0,0-.3.85L22,20.68v.14s0,.08,0,.08a.19.19,0,0,1-.14.07.21.21,0,0,1-.15,0l-3-2.2a.72.72,0,0,0-.47-.15.8.8,0,0,0-.48.15l-3,2.2a.21.21,0,0,1-.15,0,.31.31,0,0,1-.14-.07.12.12,0,0,1,0-.08v-.14l1.14-3.5a.71.71,0,0,0-.29-.85l-2.84-2.17,0,0s-.08-.08-.08-.11a.21.21,0,0,1,0-.15,0,0,0,0,1,0,0s0,0,.07,0,.07,0,.11,0h3.72a.78.78,0,0,0,.73-.55L18.1,9.68a.12.12,0,0,1,.11-.11.14.14,0,0,0,.08,0s.07,0,.07,0l.11.11,1.14,3.53a.84.84,0,0,0,.74.55h3.71a.12.12,0,0,1,.11,0s0,0,.08,0,0,0,0,0,0,.07,0,.15A.45.45,0,0,0,24.17,14.13Z" }),
        React.createElement("path", { d: "M26.89,7a12.25,12.25,0,1,0,3.57,8.64A12.15,12.15,0,0,0,26.89,7ZM25.83,23.21a10.69,10.69,0,0,1-15.12,0,10.65,10.65,0,0,1,0-15.08A10.55,10.55,0,0,1,18.25,5a10.71,10.71,0,0,1,7.58,18.24Z" })))); };
exports.IconMedal = IconMedal;
