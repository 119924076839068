"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PaginationControls = void 0;
var PaginationControls = /** @class */ (function () {
    function PaginationControls() {
        var _this = this;
        /**
         * Accepts a pathname with a query param and extracts the page number from it.
         * EG: "/users?page=1422"
         */
        this.getPageParam = function (pathname) {
            var _a;
            var qs = (_a = pathname === null || pathname === void 0 ? void 0 : pathname.split('?')) === null || _a === void 0 ? void 0 : _a[1];
            var formattedQs = new URLSearchParams(qs);
            return parseInt(formattedQs.get('page') || '', 10) || undefined;
        };
        /**
         * Accepts a pathname with a query param and checks whether the page param exists.
         * EG: "/users?page=1422"
         */
        this.hasPageParam = function (pathname) {
            var _a;
            var qs = (_a = pathname === null || pathname === void 0 ? void 0 : pathname.split('?')) === null || _a === void 0 ? void 0 : _a[1];
            if (!qs)
                return false;
            return new URLSearchParams(qs).has('page');
        };
        this.getAllPages = function (client) { return __awaiter(_this, void 0, void 0, function () {
            var data, nextPageNumber, response, controls;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        data = [];
                        nextPageNumber = 1;
                        _a.label = 1;
                    case 1:
                        if (!nextPageNumber) return [3 /*break*/, 3];
                        return [4 /*yield*/, client({ page: nextPageNumber })];
                    case 2:
                        response = _a.sent();
                        controls = this.getCoreControls(response);
                        data = data.concat(response['hydra:member']);
                        // When there is no longer a next page, this will get set to undefined and stop the while loop.
                        nextPageNumber = controls.nextPageNumber;
                        return [3 /*break*/, 1];
                    case 3: return [2 /*return*/, data];
                }
            });
        }); };
        this.getCoreControls = function (list) {
            var _a, _b, _c, _d, _e, _f, _g, _h;
            /**
             * The total number of items returned by the list.
             */
            var totalItems = (list === null || list === void 0 ? void 0 : list['hydra:totalItems']) || 0;
            /**
             * The total number of items returned on current page page.
             */
            var totalPageItems = ((_a = list === null || list === void 0 ? void 0 : list['hydra:member']) === null || _a === void 0 ? void 0 : _a.length) || 0;
            /**
             * Is the list even paginated to begin with? If "hydra:view" is missing, then the list is NOT paginated.
             */
            var isPaginated = Boolean(list === null || list === void 0 ? void 0 : list['hydra:view']);
            /**
             * First page number
             */
            var firstPageNumber = _this.getPageParam((_b = list === null || list === void 0 ? void 0 : list['hydra:view']) === null || _b === void 0 ? void 0 : _b['hydra:first']) || 1;
            /**
             * Last page number
             */
            var lastPageNumber = _this.getPageParam((_c = list === null || list === void 0 ? void 0 : list['hydra:view']) === null || _c === void 0 ? void 0 : _c['hydra:last']) || 1;
            /**
             * Whether there is a next page available
             */
            var hasNextPage = _this.hasPageParam((_d = list === null || list === void 0 ? void 0 : list['hydra:view']) === null || _d === void 0 ? void 0 : _d['hydra:next']);
            /**
             * The next page number, if defined.
             */
            var nextPageNumber = _this.getPageParam((_e = list === null || list === void 0 ? void 0 : list['hydra:view']) === null || _e === void 0 ? void 0 : _e['hydra:next']);
            /**
             * The current page number.
             */
            var currentPageNumber = _this.getPageParam((_f = list === null || list === void 0 ? void 0 : list['hydra:view']) === null || _f === void 0 ? void 0 : _f['@id']) || 1;
            /**
             * Whether there is a previous page
             */
            var hasPreviousPage = _this.hasPageParam((_g = list === null || list === void 0 ? void 0 : list['hydra:view']) === null || _g === void 0 ? void 0 : _g['hydra:previous']);
            /**
             * The previous page number
             */
            var previousPageNumber = hasPreviousPage ? _this.getPageParam((_h = list === null || list === void 0 ? void 0 : list['hydra:view']) === null || _h === void 0 ? void 0 : _h['hydra:previous']) : undefined;
            var currentRangeStart = hasNextPage
                ? currentPageNumber * totalPageItems - (totalPageItems - 1)
                : totalItems - (totalPageItems - 1);
            var currentRangeEnd = hasNextPage ? currentPageNumber * totalPageItems : totalItems;
            return {
                isPaginated: isPaginated,
                totalItems: totalItems,
                totalPageItems: totalPageItems,
                hasNextPage: hasNextPage,
                nextPageNumber: nextPageNumber,
                currentPageNumber: currentPageNumber,
                hasPreviousPage: hasPreviousPage,
                previousPageNumber: previousPageNumber,
                currentRangeEnd: currentRangeEnd,
                currentRangeStart: currentRangeStart,
                firstPageNumber: firstPageNumber,
                lastPageNumber: lastPageNumber
            };
        };
        this.getPageParam = this.getPageParam.bind(this);
        this.hasPageParam = this.hasPageParam.bind(this);
        this.getAllPages = this.getAllPages.bind(this);
        this.getCoreControls = this.getCoreControls.bind(this);
    }
    return PaginationControls;
}());
exports.PaginationControls = PaginationControls;
