"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TransportRequestClient = void 0;
var BaseClient_1 = require("../../BaseClient");
var TransportRequestClient = /** @class */ (function (_super) {
    __extends(TransportRequestClient, _super);
    function TransportRequestClient(axiosClient) {
        var _this = _super.call(this, axiosClient) || this;
        _this.retrieve = _this.retrieve.bind(_this);
        _this.cancel = _this.cancel.bind(_this);
        _this.retrieveCancellationCosts = _this.retrieveCancellationCosts.bind(_this);
        _this.create = _this.create.bind(_this);
        _this.listTransportJobAccountLinks = _this.listTransportJobAccountLinks.bind(_this);
        _this.retrieveOpenForUpsells = _this.retrieveOpenForUpsells.bind(_this);
        _this.retrieveLiveTrackingStatus = _this.retrieveLiveTrackingStatus.bind(_this);
        _this.list = _this.list.bind(_this);
        _this.sendComplaint = _this.sendComplaint.bind(_this);
        return _this;
    }
    /**
     * Retrieves the transport request associated with the given ID.
     * All fields are already expanded.
     */
    TransportRequestClient.prototype.retrieve = function (args) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.fetch({ method: 'GET', url: "/transport_requests/".concat(args.id) })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Cancels a transport request.
     */
    TransportRequestClient.prototype.cancel = function (args) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.fetch({ method: 'POST', data: {}, url: "/transport_requests/".concat(args.id, "/cancel") })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Retrieve cancellation costs for a transport request.
     */
    TransportRequestClient.prototype.retrieveCancellationCosts = function (args) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.fetch({ method: 'GET', data: {}, url: "/transport_requests/".concat(args.id, "/cancellation_costs") })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Creates a transport request.
     */
    TransportRequestClient.prototype.create = function (data) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.fetch({ method: 'POST', url: '/transport_requests', data: data })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Retrieves the Transport Job Account Links associated with the given transport request ID.
     */
    TransportRequestClient.prototype.listTransportJobAccountLinks = function (args) {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, this.fetch({
                            method: 'GET',
                            params: { state: (_a = args.state) === null || _a === void 0 ? void 0 : _a.join(',') },
                            url: "/transport_requests/".concat(args.id, "/transport_job_account_links")
                        })];
                    case 1: return [2 /*return*/, _b.sent()];
                }
            });
        });
    };
    /**
     * Open for upsells, retrieves statusses per field if they are open for upsell, by request id.
     */
    TransportRequestClient.prototype.retrieveOpenForUpsells = function (args) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.fetch({
                            method: 'GET',
                            url: "/transport_requests/".concat(args.id, "/open_for_upsells")
                        })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Retrieve live tracking status
     */
    TransportRequestClient.prototype.retrieveLiveTrackingStatus = function (args) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.fetch({
                            method: 'GET',
                            url: "/transport_requests/".concat(args.id, "/live_tracking_status")
                        })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Retrieve a list of transport requests.
     * Will only return items when user is authenticated.
     */
    TransportRequestClient.prototype.list = function (listParams) {
        return __awaiter(this, void 0, void 0, function () {
            var _a, cancelToken, params;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = listParams || {}, cancelToken = _a.cancelToken, params = __rest(_a, ["cancelToken"]);
                        return [4 /*yield*/, this.fetch({
                                method: 'GET',
                                url: "/transport_requests",
                                params: params,
                                cancelToken: cancelToken
                            })];
                    case 1: return [2 /*return*/, _b.sent()];
                }
            });
        });
    };
    /**
     * Retrieve a list of transport requests.
     * Will only return items when user is authenticated.
     */
    TransportRequestClient.prototype.sendComplaint = function (args) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.fetch({
                            method: 'POST',
                            url: "/transport_requests/".concat(args.id, "/send_complaint"),
                            data: { complaint: args.complaint }
                        })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    return TransportRequestClient;
}(BaseClient_1.BaseClient));
exports.TransportRequestClient = TransportRequestClient;
