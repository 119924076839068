import {
  CustomEvents,
  FormClasses,
  InputClasses,
  Paths,
  ResetFieldsAndValues,
  WidgetFields,
  Cookie,
} from '../interface';
import { jsonToQueryParams } from '../helpers/jsonToQueryString';
import { getFilteredAddress } from '../helpers/getFilteredAddress';
import { _AUTOCOMPLETE_SELECTED } from './autocompleteInput';
import { removeResetFlag } from './resetLogic';
import { trackTimeRelatedEvents } from '../helpers/track';
import { getCustomerBaseUrl } from '../global';
import { getWidgetContainerSelector } from './getWidgetContainerSelector';
import { AccountUtmParams } from '@brenger/api-client';
import { getSearchParams } from './parseSearchParams';
import { cookie } from '../helpers/cookie';
import { getAbTestData } from './getAbVersion';
import { logException } from '../sentry';

const initAutocompleteForm = (): void => {
  const forms: NodeListOf<HTMLFormElement> = document.querySelectorAll(FormClasses.MAIN);

  for (let i = 0; i < forms.length; i++) {
    forms[i].addEventListener('submit', event => {
      event.preventDefault();
      if (isAutocompleteValid()) {
        handleSuccess(event);
        return;
      }
      const pickupWrapper = (event.target as HTMLFormElement).querySelector(
        `.${WidgetFields.PICKUP} .${InputClasses.BASE}`
      );
      const deliveryWrapper = (event.target as HTMLFormElement).querySelector(
        `.${WidgetFields.DELIVERY}  .${InputClasses.BASE}`
      );
      if (!localStorage.getItem(WidgetFields.PICKUP) && pickupWrapper) {
        handleError(pickupWrapper);
      }
      if (!localStorage.getItem(WidgetFields.DELIVERY) && deliveryWrapper) {
        handleError(deliveryWrapper);
      }
    });
  }
};

export default initAutocompleteForm;

const isAutocompleteValid = (): boolean => {
  return !!(localStorage.getItem(WidgetFields.PICKUP) && localStorage.getItem(WidgetFields.DELIVERY));
};

const BRENGER_CM = 'BRENGER_CM';

type ConsentType = 'ad_storage' | 'ad_user_data' | 'ad_personalization' | 'analytics_storage';
type ConsentCondition = 'granted' | 'denied';
type Consent = Record<ConsentType, ConsentCondition>;

const getConsent = (): Consent | undefined => {
  const parts = `; ${document.cookie}`.split(`; ${BRENGER_CM}=`);
  if (parts.length === 2) return JSON.parse(parts.pop()?.split(';').shift() || '');
};

const handleSuccess = (submitEvent: SubmitEvent): void => {
  /* Start tracking */
  trackTimeRelatedEvents();
  /* Proceed handling success */
  const pickup = getFilteredAddress(JSON.parse(localStorage.getItem(WidgetFields.PICKUP) || ''));
  const delivery = getFilteredAddress(JSON.parse(localStorage.getItem(WidgetFields.DELIVERY) || ''));
  const resetParam = localStorage.getItem(ResetFieldsAndValues.STORAGE_KEY)
    ? `${ResetFieldsAndValues.PARAM_KEY}=${localStorage.getItem(ResetFieldsAndValues.STORAGE_KEY)}`
    : null;
  if (
    window.location.href.indexOf(getCustomerBaseUrl()) > -1 ||
    window.location.href.indexOf(Paths.GENERAL_FLOW) > -1
  ) {
    // make sure destination details are updated
    const pickupEvent = new CustomEvent(_AUTOCOMPLETE_SELECTED, {
      detail: {
        addressDetails: pickup,
        name: WidgetFields.PICKUP,
      },
    });
    document.dispatchEvent(pickupEvent);
    const deliveryEvent = new CustomEvent(_AUTOCOMPLETE_SELECTED, {
      detail: {
        addressDetails: delivery,
        name: WidgetFields.DELIVERY,
      },
    });
    document.dispatchEvent(deliveryEvent);
    // push new step
    const customEvent = new CustomEvent(CustomEvents.WIDGET_INTERNAL_SUBMIT, {
      detail: {
        url: Paths.NEXT_PATH,
        [AccountUtmParams.PICKUP]: getSearchParams().get(AccountUtmParams.PICKUP),
        [AccountUtmParams.DELIVERY]: getSearchParams().get(AccountUtmParams.DELIVERY),
      },
    });
    document.dispatchEvent(customEvent);
    removeResetFlag();
    return;
  }

  /**
   * From here we are building the redirect url, from widget to GF
   */
  const referrer = window.location.origin + window.location.pathname;
  const minimumParamsNeeded = [
    jsonToQueryParams(pickup, 'pickup'),
    jsonToQueryParams(delivery, 'delivery'),
    jsonToQueryParams({ referrer }, 'meta'),
  ];

  /**
   * Every parameter that we add from this point is bonus info or convenience
   * Sometimes we end up with a malformed uri, so we wrap this operation in a try catch
   */
  try {
    // Include the minimum params as new array, so we can reuse it later
    const params = [...minimumParamsNeeded];
    const widgetContainer = submitEvent.submitter?.closest(getWidgetContainerSelector());
    // Array of field names maps eventually to a Q and the attribute's value is the answer
    // We can be used to skipp a step in the GF.
    let resetFlag = false;
    ['pickup-type', 'pickup-type-specific', 'ps-action', 'ps-value'].forEach(fieldName => {
      // we check the widget container for:
      // data-brenger-FIELDNAME attribute, if exist we pass it over to the react app
      const value = widgetContainer?.getAttribute(`data-brenger-${fieldName}`);
      if (value) {
        resetFlag = true;
        params.push(`${fieldName}=${encodeURIComponent(value)}`);
      }
    });
    // First we add utms we stored in via a cookie
    const utmCookies = cookie.get(Cookie.UTM_LANDING);
    if (utmCookies) {
      const parsedUtmCookies = JSON.parse(utmCookies);
      Object.keys(parsedUtmCookies).forEach(utmKey => {
        params.push(`${utmKey}=${parsedUtmCookies[utmKey]}`);
      });
    }
    // Then we check for prefill utms
    const searchParams = getSearchParams();
    searchParams.forEach((value, key) => {
      if ([AccountUtmParams.PICKUP, AccountUtmParams.DELIVERY].includes(key as AccountUtmParams)) {
        // We need to include the account prefill utm with the search params
        // The reason we don't store these: it will cause a prefill, which we only want to do if the land on a particular landing page
        params.push(`${key}=${value}`);
        resetFlag = true;
      }
    });
    // If we send users to a different url, then we pass allong consent choices
    if (typeof BRENGER_TR_FLOW !== 'undefined') {
      const consent = getConsent();
      if (consent) {
        Object.keys(consent).forEach(key => {
          params.push(`${key}=${consent[key]}`);
        });
      }
    }

    const abTests = getAbTestData();
    if (abTests.length) {
      const tests = abTests.map(t => [t.eid, t.variation]);
      params.push(`wpab=${encodeURIComponent(JSON.stringify(tests))}`);
    }

    // See if we have a clarity identifier, and pass it along
    const clarityId = sessionStorage.getItem('br_mc_id');
    if (clarityId) {
      params.push(`br_mc_id=${clarityId}`);
    }

    // If there is a utm present, we always want to reset
    if (resetFlag) {
      params.push(`${ResetFieldsAndValues.PARAM_KEY}=${ResetFieldsAndValues.FULL}`);
    } else if (resetParam) {
      // We only flag for resetting if there is a reset param present
      params.push(resetParam);
    }

    const pathWithParams = `${Paths.NEXT_PATH}?${params.join('&')}`;
    removeResetFlag();
    window.location.href = `${getCustomerBaseUrl()}${pathWithParams}`;
  } catch (e) {
    logException('Redirect widget to GF failed - using minimum info to redirect', e);
    // When end up in the catch it is high likely that we are dealing with a malformed url
    // so we use the minimum required info with a full reset param to redirected the user

    // add reset param
    minimumParamsNeeded.push(`${ResetFieldsAndValues.PARAM_KEY}=${ResetFieldsAndValues.FULL}`);

    const pathWithParams = `${Paths.NEXT_PATH}?${minimumParamsNeeded.join('&')}`;
    window.location.href = `${getCustomerBaseUrl()}${pathWithParams}`;
  }
};

const handleError = (inputWrapper: Element): void => {
  inputWrapper.classList.add(InputClasses.HAS_FEEDBACK, InputClasses.SHOW_ZERO_RESULT);
  const input = inputWrapper.querySelector('input');
  const feedback = inputWrapper.querySelector(`.${InputClasses.FEEDBACK}`);
  if (!feedback || !input) {
    return;
  }
  let error = feedback.getAttribute('data-error-no-value') || '';
  if (input.value.length > 0) {
    error = feedback.getAttribute('data-error-list') || '';
  }
  feedback.innerHTML = error;
};

export const doubleCheckMpUrl = (referrer: string): string => {
  /**
   * Found out that MP sents a malformed utm url
   * So checking if it is the very specific case, and if so strip the malformed part
   * else just return url as is.
   */
  if (referrer.slice(-4) === '&utm') {
    return referrer.slice(0, -4);
  }
  return referrer;
};
