"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.CoreClient = void 0;
var Account_1 = require("./clients/Account");
var BankAccount_1 = require("./clients/BankAccount");
var Contact_1 = require("./clients/Contact");
var Delivery_1 = require("./clients/Delivery");
var Pickup_1 = require("./clients/Pickup");
var User_1 = require("./clients/User");
var TransportJobAccountLink_1 = require("./clients/TransportJobAccountLink");
var TransportJob_1 = require("./clients/TransportJob");
var TransportRequest_1 = require("./clients/TransportRequest");
var Route_1 = require("./clients/Route");
var Review_1 = require("./clients/Review");
var Stop_1 = require("./clients/Stop");
var OpenTransportJob_1 = require("./clients/OpenTransportJob");
var Address_1 = require("./clients/Address");
var Signature_1 = require("./clients/Signature");
var PhotoProof_1 = require("./clients/PhotoProof");
var Message_1 = require("./clients/Message");
var Conversation_1 = require("./clients/Conversation");
var Payment_1 = require("./clients/Payment");
var PreferredRoute_1 = require("./clients/PreferredRoute");
var ItemSet_1 = require("./clients/ItemSet");
var DateTimePeriod_1 = require("./clients/DateTimePeriod");
var ProductPayment_1 = require("./clients/ProductPayment");
var Vehicle_1 = require("./clients/Vehicle");
var Mandate_1 = require("./clients/Mandate");
var DriverLocation_1 = require("./clients/DriverLocation");
var DayRoute_1 = require("./clients/DayRoute");
var ApiKey_1 = require("./clients/ApiKey");
var Customer_1 = require("./clients/Customer");
var TransportRequestQuote_1 = require("./clients/TransportRequestQuote");
var Invoice_1 = require("./clients/Invoice");
var DayRouteActivity_1 = require("./clients/DayRouteActivity");
var PaymentMethod_1 = require("./clients/PaymentMethod");
var DamageReport_1 = require("./clients/DamageReport");
var Newsletter_1 = require("./clients/Newsletter");
var DriverReview_1 = require("./clients/DriverReview");
var Image_1 = require("./clients/Image");
var Axios_1 = require("./utils/Axios");
var CoreClient = /** @class */ (function (_super) {
    __extends(CoreClient, _super);
    function CoreClient(axiosInstance) {
        var _this = _super.call(this) || this;
        _this.accounts = new Account_1.AccountClient(axiosInstance);
        _this.addresses = new Address_1.AddressClient(axiosInstance);
        _this.bankAccounts = new BankAccount_1.BankAccountClient(axiosInstance);
        _this.contacts = new Contact_1.ContactClient(axiosInstance);
        _this.deliveries = new Delivery_1.DeliveryClient(axiosInstance);
        _this.messages = new Message_1.MessageClient(axiosInstance);
        _this.conversations = new Conversation_1.ConversationClient(axiosInstance);
        _this.openTransportJobs = new OpenTransportJob_1.OpenTransportJobClient(axiosInstance);
        _this.pickups = new Pickup_1.PickupClient(axiosInstance);
        _this.reviews = new Review_1.ReviewClient(axiosInstance);
        _this.transportJobAccountLinks = new TransportJobAccountLink_1.TransportJobAccountLinkClient(axiosInstance);
        _this.transportJobs = new TransportJob_1.TransportJobClient(axiosInstance);
        _this.transportRequests = new TransportRequest_1.TransportRequestClient(axiosInstance);
        _this.routes = new Route_1.RouteClient(axiosInstance);
        _this.stops = new Stop_1.StopClient(axiosInstance);
        _this.users = new User_1.UserClient(axiosInstance);
        _this.signatures = new Signature_1.SignatureClient(axiosInstance);
        _this.photoProofs = new PhotoProof_1.PhotoProofClient(axiosInstance);
        _this.payments = new Payment_1.PaymentClient(axiosInstance);
        _this.paymentMethods = new PaymentMethod_1.PaymentMethodClient(axiosInstance);
        _this.productPayments = new ProductPayment_1.ProductPaymentClient(axiosInstance);
        _this.preferredRoutes = new PreferredRoute_1.PreferredRouteClient(axiosInstance);
        _this.itemSets = new ItemSet_1.ItemSetClient(axiosInstance);
        _this.dateTimePeriods = new DateTimePeriod_1.DateTimePeriodClient(axiosInstance);
        _this.vehicles = new Vehicle_1.VehicleClient(axiosInstance);
        _this.mandates = new Mandate_1.MandateClient(axiosInstance);
        _this.driverLocations = new DriverLocation_1.DriverLocationClient(axiosInstance);
        _this.dayRoutes = new DayRoute_1.DayRouteClient(axiosInstance);
        _this.dayRoutesActivities = new DayRouteActivity_1.DayRouteActivityClient(axiosInstance);
        _this.apiKeys = new ApiKey_1.ApiKeyClient(axiosInstance);
        _this.customers = new Customer_1.CustomerClient(axiosInstance);
        _this.transportRequestQuotes = new TransportRequestQuote_1.TransportRequestQuoteClient(axiosInstance);
        _this.invoices = new Invoice_1.InvoiceClient(axiosInstance);
        _this.damageReports = new DamageReport_1.DamageReportClient(axiosInstance);
        _this.newsletter = new Newsletter_1.NewsletterClient(axiosInstance);
        _this.driverReviews = new DriverReview_1.DriverReviewClient(axiosInstance);
        _this.images = new Image_1.ImageClient(axiosInstance);
        return _this;
    }
    return CoreClient;
}(Axios_1.AxiosUtils));
exports.CoreClient = CoreClient;
