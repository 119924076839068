"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AxiosUtils = void 0;
var AxiosUtils = /** @class */ (function () {
    function AxiosUtils() {
        var _this = this;
        this.updateDefaultHeaders = function (headers) {
            /**
             * The idea here is:
             * - loop over the client methods, with Object.keys() => foreach
             * - Some type checks to see if we can set the headers
             * - Set the header, by looping over the header keys
             */
            Object.keys(_this).forEach(function (method) {
                var _a, _b, _c;
                // Because we want to loop over methods of classes that are extended with this AxiosUtils class
                // TS has a hard time trusting us here, landed on this to make it hush (not proud of it).
                var axios = (_a = _this[method]) === null || _a === void 0 ? void 0 : _a.http;
                if ((_c = (_b = axios === null || axios === void 0 ? void 0 : axios.defaults) === null || _b === void 0 ? void 0 : _b.headers) === null || _c === void 0 ? void 0 : _c.common) {
                    Object.keys(headers).forEach(function (headerKey) {
                        axios.defaults.headers.common[headerKey] = headers[headerKey];
                    });
                }
            });
        };
    }
    return AxiosUtils;
}());
exports.AxiosUtils = AxiosUtils;
