import '../scss/widget.scss';
import { cleanUpTracking, getTimeInRoundedSeconds, TrackingStorageKeys, trackZeroResultsInput } from './helpers/track';
import { Cookie, CustomEvents, FormClasses, InputClasses, WidgetFields } from './interface';
import { renderWidget } from './render/widget';
import initAutocompleteForm from './widget/autocompleteForm';
import initAutocompleteInputs, { reHydrateInputs } from './widget/autocompleteInput';
import { initAutocompleteStickyCTA } from './widget/autocompleteStickyCTA';
import { getWidgetContainerSelector } from './widget/getWidgetContainerSelector';
import { getSearchParams, parseSearchParams } from './widget/parseSearchParams';
import { initSentry } from './sentry';
import { fontLoader } from '@brenger/react';
import { cookie } from './helpers/cookie';
import { AccountUtmParams } from '@brenger/api-client';

document.addEventListener('DOMContentLoaded', () => {
  // kickoff widget rendering
  document.dispatchEvent(new CustomEvent(CustomEvents.WIDGET_RENDER));

  // init Sentry
  initSentry();

  fontLoader();

  // Attach focus event on click to buttons with that attribute
  const focusButtons = document.querySelectorAll('[data-brenger-tr-widget-focus]');
  if (focusButtons.length) {
    for (let i = 0; i < focusButtons.length; i++) {
      focusButtons[i].addEventListener('click', () => {
        document.dispatchEvent(new CustomEvent(CustomEvents.WIDGET_FOCUS));
      });
    }
  }
  const searchParams = getSearchParams();
  // Object to hold UTM key/value, we will store it as cookie, so we can pass it on later
  const utmParams = {};
  searchParams.forEach((value, key) => {
    if (key.includes('utm')) {
      // NOTE: We don't want the pickup/delivery utm to stick around
      // These should still be read from url
      if (![AccountUtmParams.PICKUP, AccountUtmParams.DELIVERY].includes(key as AccountUtmParams)) {
        utmParams[key] = encodeURIComponent(value);
      }
    }
  });
  // Save cookie utms as cookie
  if (Object.keys(utmParams).length) cookie.create(Cookie.UTM_LANDING, JSON.stringify(utmParams), 30);
});

document.addEventListener(CustomEvents.WIDGET_FOCUS, (): void => {
  const form = document.querySelector(FormClasses.MAIN) as HTMLElement;
  if (!form) {
    return;
  }
  let input = document.querySelector(`[name="${WidgetFields.PICKUP}"]`) as HTMLInputElement;
  // check for value, else set to delivery
  if (input && input.value) {
    input = document.querySelector(`[name="${WidgetFields.DELIVERY}"]`) as HTMLInputElement;
  }
  // check if there is no value else don't focus
  if (input && !input.value) {
    input.focus();
  }
  const isIos =
    ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document);
  const isAndroid = navigator.userAgent.toLowerCase().indexOf('android') > -1;
  // Scroll to input will be taken care by focussing on the input
  if (!isIos && !isAndroid) {
    return;
  }
  // scroll to form
  const scrollPos = form.offsetTop - window.innerHeight / 2 + 200;
  window.scroll({
    top: scrollPos,
    left: 0,
    behavior: 'smooth',
  });
});

document.addEventListener(CustomEvents.WIDGET_RENDER, () => {
  // in case of a full refresh, we would like to have a clean starting point, otherwise session storage will take care of it
  cleanUpTracking();
  // Set dom ready start time
  sessionStorage.setItem(TrackingStorageKeys.DOM_READY_TIMESTAMP, String(getTimeInRoundedSeconds()));

  const widgetEl = document.querySelector(getWidgetContainerSelector());
  if (!widgetEl) {
    return;
  }
  renderWidget(widgetEl);
  // check if we need a sticky button
  if (!document.querySelector('[data-brenger-tr-sticky]')) {
    return;
  }
  initAutocompleteStickyCTA();
});

document.addEventListener(CustomEvents.WIDGET_INIT, async () => {
  // init and attach functions to form and inputs
  initAutocompleteForm();
  initAutocompleteInputs(document.querySelectorAll(`.${InputClasses.AUTO_COMPLETE_INPUT}`));
  // try to prefill
  await parseSearchParams();
});

window.onpageshow = event => {
  // Safari is loading pages from cache, when clicking back button.
  // When it does, then the persisted flag is true, all markup is in place, but we should rehydrate the inputs manually
  if (event.persisted) {
    reHydrateInputs(document.querySelectorAll(`.${InputClasses.AUTO_COMPLETE_INPUT}`));
  }
};

window.onbeforeunload = () => {
  // clean up / catching last bits
  trackZeroResultsInput();
};
