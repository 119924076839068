import { CustomEvents, ResetFieldsAndValues, WidgetFields } from '../interface';

export const fireFullResetEvent = (name = ''): void => {
  const event = new CustomEvent(CustomEvents.WIDGET_RESET, {
    detail: {
      name,
    },
  });
  document.dispatchEvent(event);
};

document.addEventListener(CustomEvents.WIDGET_RESET, (event: CustomEvent): void => {
  // Temporary added a question mark to prevent the error from happening
  // See https://gitlab.com/brenger/frontend/transport-requests/-/issues/1215 for a better solution
  const name = event.detail?.name;
  // remove localstorage
  if (!name) {
    localStorage.removeItem(WidgetFields.PICKUP);
    localStorage.removeItem(WidgetFields.DELIVERY);
  } else {
    localStorage.removeItem(name);
  }
  const selectorString = !name ? `[name=${WidgetFields.PICKUP}], [name=${WidgetFields.DELIVERY}]` : `[name=${name}]`;
  // make inputs empty
  const inputs: NodeListOf<HTMLInputElement> = document.querySelectorAll(selectorString);
  for (let i = 0; i < inputs.length; i++) {
    inputs[i].value = '';
    inputs[i].dispatchEvent(new Event('change'));
  }
  // raise reset flag for entering flow
  localStorage.setItem(ResetFieldsAndValues.STORAGE_KEY, ResetFieldsAndValues.FULL);
});

export const resetDatesOnly = (): void => {
  // raise reset dates flag for entering flow
  localStorage.setItem(ResetFieldsAndValues.STORAGE_KEY, ResetFieldsAndValues.DATE);
};

export const removeResetFlag = (): void => {
  // Remove reset flag
  localStorage.removeItem(ResetFieldsAndValues.STORAGE_KEY);
};
