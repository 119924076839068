import { User } from '@brenger/api-client';
import { _get } from '../helpers/getLodash';
import { missingImageSrc } from '../global';

export const getProfileImage = (user: User): string => {
  return _get(user, 'profile_image') || missingImageSrc;
};

export const hasDedicatedFlow = (user: User): boolean => {
  return _get(user, 'account.has_business_flow', false);
};

export const getNewTransportRequestLink = (user: User, baseUrl: string): string => {
  if (hasDedicatedFlow(user)) {
    return `${baseUrl}/business/transport_request`;
  }
  return `${baseUrl}/transport_request`;
};

export const getCustomerDashboardLink = (baseUrl: string): string => {
  return `${baseUrl}/dashboard`;
};

export const getAccountType = (user: User): string => {
  return _get(user, 'account.account_type', 'individual');
};
