import icon from './icons';
import autocompleteInput from './autocompleteInput';
import { translate } from '../helpers/translations';
import { CustomEvents, WidgetFields } from '../interface';

export const renderWidget = (element: Element): void => {
  element.innerHTML = `
  <form class="brenger-tr-widget--wrapper">
    <div class="brenger-tr-widget--header">${translate('widget.title_short')}</div>
    <div class="brenger-tr-widget">
        ${icon('route')}
        <div class="destination-fields">
          ${autocompleteInput({
            name: WidgetFields.PICKUP,
            label: translate('widget.fields.from.label_short'),
            placeHolder: translate('widget.fields.from.place_holder'),
            error: `${translate('widget.fields.from.error')}`,
            error_no_value: translate('widget.fields.from.error_no_value'),
          })}
          <div class="brenger-tr-widget--divider"></div>
          ${autocompleteInput({
            name: WidgetFields.DELIVERY,
            label: translate('widget.fields.to.label_short'),
            placeHolder: translate('widget.fields.to.place_holder'),
            error: `${translate('widget.fields.from.error')}`,
            error_no_value: translate('widget.fields.to.error_no_value'),
          })}
        </div>
        <button class="btn btn--secondary btn--icon-right btn--full-width widget">
          ${translate('widget.submit')} ${icon('arrow')}
        </button>
    </div>
  </div>
  </form>
  `;

  // done
  const event = new CustomEvent(CustomEvents.WIDGET_INIT);
  document.dispatchEvent(event);
};
