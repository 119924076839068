/* eslint-disable */
export const Config = Object.freeze({
  NODE_ENV: process.env.NODE_ENV,
  API_URL: process.env.API_URL || 'https://api.brenger.nl',
  GEO_API_URL: process.env.GEO_API_URL || 'https://geo.brenger.nl',
  BRENGER_BASE_URL: process.env.BRENGER_BASE_URL || 'https://www.brenger.nl',
  BRENGER_DRIVER_BASE_URL: process.env.BRENGER_DRIVER_BASE_URL || 'https://driver.brenger.nl',
  TRANSLATIONS_URL: 'https://api.brenger.nl',
  SENTRY_DSN: process.env.SENTRY_DSN_WIDGET,
  LOG: process.env.LOG === 'true',
  PRICE_API_URL: process.env.PRICE_API_URL,
  ASSETS_PATH: process.env.ASSETS_PATH,
  SUPPORTED_LANGUAGES: ['nl', 'nl-be', 'en-nl', 'de-de', 'es-es'], // should be same locale setup as the content site
  TRACKING_BASE_URL: process.env.TRACKING_BASE_URL || 'https://live.brenger.nl',
  COMMIT_SHA: process.env.CI_COMMIT_SHA, // provided by gitlab CI
});
