"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseCorePaginationControls = void 0;
/**
 * Accepts a pathname with a query param and extracts the page number from it.
 * EG: "/users?page=1422"
 */
var getPageParam = function (pathname) {
    var _a;
    var qs = (_a = pathname === null || pathname === void 0 ? void 0 : pathname.split('?')) === null || _a === void 0 ? void 0 : _a[1];
    var formattedQs = new URLSearchParams(qs);
    return parseInt(formattedQs.get('page') || '', 10) || undefined;
};
/**
 * Accepts a pathname with a query param and checks whether the page param exists.
 * EG: "/users?page=1422"
 */
var hasPageParam = function (pathname) {
    var _a;
    var qs = (_a = pathname === null || pathname === void 0 ? void 0 : pathname.split('?')) === null || _a === void 0 ? void 0 : _a[1];
    if (!qs)
        return false;
    return new URLSearchParams(qs).has('page');
};
var parseCorePaginationControls = function (list) {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    /**
     * The total number of items returned by the list.
     */
    var totalItems = (list === null || list === void 0 ? void 0 : list['hydra:totalItems']) || 0;
    /**
     * The total number of items returned on current page page.
     */
    var totalPageItems = ((_a = list === null || list === void 0 ? void 0 : list['hydra:member']) === null || _a === void 0 ? void 0 : _a.length) || 0;
    /**
     * Is the list even paginated to begin with? If "hydra:view" is missing, then the list is NOT paginated.
     */
    var isPaginated = Boolean(list === null || list === void 0 ? void 0 : list['hydra:view']);
    /**
     * First page number
     */
    var firstPageNumber = getPageParam((_b = list === null || list === void 0 ? void 0 : list['hydra:view']) === null || _b === void 0 ? void 0 : _b['hydra:first']) || 1;
    /**
     * Last page number
     */
    var lastPageNumber = getPageParam((_c = list === null || list === void 0 ? void 0 : list['hydra:view']) === null || _c === void 0 ? void 0 : _c['hydra:last']) || 1;
    /**
     * Whether there is a next page available
     */
    var hasNextPage = hasPageParam((_d = list === null || list === void 0 ? void 0 : list['hydra:view']) === null || _d === void 0 ? void 0 : _d['hydra:next']);
    /**
     * The next page number, if defined.
     */
    var nextPageNumber = getPageParam((_e = list === null || list === void 0 ? void 0 : list['hydra:view']) === null || _e === void 0 ? void 0 : _e['hydra:next']);
    /**
     * The current page number.
     */
    var currentPageNumber = getPageParam((_f = list === null || list === void 0 ? void 0 : list['hydra:view']) === null || _f === void 0 ? void 0 : _f['@id']) || 1;
    /**
     * Whether there is a previous page
     */
    var hasPreviousPage = hasPageParam((_g = list === null || list === void 0 ? void 0 : list['hydra:view']) === null || _g === void 0 ? void 0 : _g['hydra:previous']);
    /**
     * The previous page number
     */
    var previousPageNumber = hasPreviousPage ? getPageParam((_h = list === null || list === void 0 ? void 0 : list['hydra:view']) === null || _h === void 0 ? void 0 : _h['hydra:previous']) : undefined;
    var currentRangeStart = hasNextPage
        ? currentPageNumber * totalPageItems - (totalPageItems - 1)
        : totalItems - (totalPageItems - 1);
    var currentRangeEnd = hasNextPage ? currentPageNumber * totalPageItems : totalItems;
    return {
        isPaginated: isPaginated,
        totalItems: totalItems,
        totalPageItems: totalPageItems,
        hasNextPage: hasNextPage,
        nextPageNumber: nextPageNumber,
        currentPageNumber: currentPageNumber,
        hasPreviousPage: hasPreviousPage,
        previousPageNumber: previousPageNumber,
        currentRangeEnd: currentRangeEnd,
        currentRangeStart: currentRangeStart,
        firstPageNumber: firstPageNumber,
        lastPageNumber: lastPageNumber
    };
};
exports.parseCorePaginationControls = parseCorePaginationControls;
