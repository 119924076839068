"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LocaleNormalizer = void 0;
var LocaleNormalizer = /** @class */ (function () {
    function LocaleNormalizer(args) {
        this.defaultLocale = args.defaultLocale;
        this.defaultTimeZone = args.defaultTimeZone || 'Europe/Amsterdam';
        this.validLocales = args.validLocales;
        this.HYPHEN = '-';
        this.UNDERSCORE = '_';
    }
    LocaleNormalizer.prototype.normalizeLocale = function (rawLocale) {
        return rawLocale.replace(this.UNDERSCORE, this.HYPHEN);
    };
    LocaleNormalizer.prototype.parseLocale = function (rawLocale) {
        var locale = this.normalizeLocale(rawLocale);
        var isLocaleSameAsDefault = locale === this.defaultLocale;
        if (isLocaleSameAsDefault)
            return locale;
        var isLocaleCorrectSize = locale.length === 5;
        var isLocaleValid = isLocaleCorrectSize && this.validLocales.includes(locale);
        if (isLocaleValid)
            return locale;
        var _a = locale.split(this.HYPHEN), language = _a[0], country = _a[1];
        // Does the language from given locale at least match one of the predefined locales?
        var localeWithSameLanguage = this.validLocales.find(function (d) { return d.startsWith(language); });
        if (localeWithSameLanguage)
            return localeWithSameLanguage;
        // NOTE: some locales omit the country, so important to check if we have correct size locale here.
        var localeWithSameCountry = isLocaleCorrectSize && this.validLocales.find(function (d) { return d.endsWith(country); });
        if (localeWithSameCountry)
            return localeWithSameCountry;
        return this.defaultLocale;
    };
    LocaleNormalizer.prototype.parseTimeZone = function (locale) {
        if (locale === 'de-DE') {
            return 'Europe/Berlin';
        }
        if (locale === 'es-ES') {
            return 'Europe/Madrid';
        }
        return 'Europe/Amsterdam';
    };
    LocaleNormalizer.prototype.parseLanguage = function (rawLocale) {
        var locale = this.parseLocale(rawLocale);
        // NOTE: see how Locale is created - splitting on hyphen + taking first part must be valid CoreLanguage.
        return locale.split(this.HYPHEN)[0];
    };
    return LocaleNormalizer;
}());
exports.LocaleNormalizer = LocaleNormalizer;
