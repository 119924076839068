"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.IconRecover = void 0;
var React = __importStar(require("react"));
var IconRecover = function (props) {
    return (React.createElement("svg", __assign({ width: "1em", height: "1em", viewBox: "0 0 10 12", fill: "currentColor", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("path", { d: "M4.66403 1.33597V0.292396C4.66403 0.0300447 4.34921 -0.098216 4.16847 0.0883451L2.53607 1.71492C2.41946 1.83153 2.41946 2.01226 2.53607 2.12886L4.16264 3.75544C4.34921 3.93617 4.66403 3.80791 4.66403 3.54556V2.50198C6.59377 2.50198 8.16205 4.07026 8.16205 6C8.16205 6.46057 8.0746 6.90949 7.90553 7.31176C7.81808 7.52164 7.88221 7.76067 8.03962 7.91808C8.33695 8.21541 8.83833 8.11047 8.99574 7.71986C9.21145 7.18933 9.32805 6.60632 9.32805 6C9.32805 3.42312 7.2409 1.33597 4.66403 1.33597ZM4.66403 9.49802C2.73429 9.49802 1.16601 7.92974 1.16601 6C1.16601 5.53943 1.25346 5.09051 1.42253 4.68824C1.50998 4.47836 1.44585 4.23933 1.28844 4.08192C0.991106 3.78459 0.489723 3.88953 0.332312 4.28014C0.116601 4.81067 0 5.39368 0 6C0 8.57688 2.08715 10.664 4.66403 10.664V11.7076C4.66403 11.97 4.97885 12.0982 5.15958 11.9117L6.78616 10.2851C6.90276 10.1685 6.90276 9.98774 6.78616 9.87114L5.15958 8.24456C4.97885 8.06383 4.66403 8.19209 4.66403 8.45444V9.49802V9.49802Z" })));
};
exports.IconRecover = IconRecover;
