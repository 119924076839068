import { User } from '@brenger/api-client';
import { checkLoggedIn } from './user/fetchUserState';
import { _get } from './helpers/getLodash';
import { CustomEvents, Paths } from './interface';
import getNavItems from './menu/getNavItems';
import menuMarkup from './render/menuContainer';

import '../scss/menu.scss';
import { Config } from '../config';
import { logError } from '../../src/utils/basics';
import { fontLoader } from '@brenger/react';

document.addEventListener('DOMContentLoaded', () => {
  fontLoader();
  /**
   * Skip logged in check when react app is served
   * React will do the handling, and fire off an event
   */
  if (window.location.pathname.match(/(nl|en|de|be)\/customer/)) {
    return;
  }
  checkLoggedIn();
});

document.addEventListener(CustomEvents.USER_UPDATED, ((event: CustomEvent) => {
  const user: User = _get(event, 'detail.result');
  if (!user || Object.keys(user).length === 0) {
    // user is not logged in:
    // remove classes
    removeMenuPresentClasses();
    // remove existing markup
    const menuContainer = document.querySelector('#brenger-menu-container');
    if (menuContainer) {
      menuContainer.remove();
    }
    // don't render menu
    return;
  }

  addMenuPresentClasses();

  const navItems = getNavItems(user);
  let menuDiv = document.querySelector('#brenger-menu-container');
  if (menuDiv) {
    menuDiv.innerHTML = menuMarkup(navItems);
    return;
  }
  // create
  menuDiv = document.createElement('div');
  menuDiv.id = 'brenger-menu-container';
  menuDiv.innerHTML = menuMarkup(navItems);
  // append
  document.body.appendChild(menuDiv);
}) as EventListener);

const menuClickHandler = async (event): Promise<void> => {
  const target = event.target as HTMLElement;
  if (!target) {
    return;
  }
  if (
    target.getAttribute('id') === 'brenger-burger' ||
    (target.parentElement && target.parentElement.getAttribute('id') === 'brenger-burger')
  ) {
    document.body.classList.toggle('main-nav--is-open');
  }

  // bit hacky, but easiest way out... so far only custom fetch we do.
  if (target.getAttribute('href')?.includes(`${Config.API_URL}${Paths.LOGOUT}`)) {
    event.preventDefault();

    await fetch(`${Config.API_URL}${Paths.LOGOUT}`, {
      method: 'POST',
      credentials: 'include',
    }).catch(error => {
      logError(error);
    });
    window.location.assign(Config.BRENGER_BASE_URL || 'https://www.brenger.nl');
  }
};
document.addEventListener('click', menuClickHandler);

document.addEventListener('click', event => {
  const target = event.target as HTMLElement;
  const body = document.querySelector('body');
  if (!target || !body || !target.classList.contains('nav-item--has-sub')) {
    return;
  }
  target.classList.toggle('sub-nav--is-open');
});

document.addEventListener('change', event => {
  const target = event.target as HTMLElement;
  if (!target || target.getAttribute('id') !== 'brenger-language-switcher') {
    return;
  }
  const language: string = _get(target, 'value', '');
  if (language === '') {
    return;
  }
  const pathName: string[] = window.location.pathname.split('/');
  pathName.splice(0, 2);
  window.location.href = ['/' + language, ...pathName].join('/');
});

const addMenuPresentClasses = (): void => {
  const body = document.querySelector('body');
  if (body) {
    body.classList.add('main-nav--is-present');
  }
};

const removeMenuPresentClasses = (): void => {
  const body = document.querySelector('body');
  if (body) {
    body.classList.remove('main-nav--is-present');
  }
};
