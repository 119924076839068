/*
/* BRENGER BASICS UTILS (DEPRECATED!)
/* ================================================== */

import { Config } from '../config';
import * as Sentry from '@sentry/react';

/**
 * Small util to check if where the app gets served from.
 * returns null when Brenger serves the app.
 */
export const getCrossDomainSetup = (): 'brengertransport' | null => {
  const { hostname } = window.location;
  if (hostname.includes('brengertransport.com')) {
    return 'brengertransport';
  }
  return null;
};

/**
 *
 * @param {object} event
 * @returns {null}
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const pushToDataLayer = (event: any): void => {
  // ASSUMING WE SPLIT EVERYTHING TO DEV, STAGING AND PROD WE PUSH EVERYTHING TO THE DATA LAYER
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  window.dataLayer = window.dataLayer || [];
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  window.dataLayer.push(event);
};

export const logException = (message: string, data?: Record<string, unknown>): void => {
  Sentry.withScope((scope: Sentry.Scope) => {
    data && scope.setExtras(data);
    Sentry.captureMessage(message);
  });
};

export const urlParser = (): URLSearchParams => new URLSearchParams(document.location.search);

export function logError<Message>(message: Message): void {
  if (Config.NODE_ENV === 'development' || Config.NODE_ENV === 'staging') {
    // eslint-disable-next-line no-console
    console.error(message);
  }
}
